import { handleActions } from 'redux-actions';

import {
  stateUpdate,
  State,
} from './action';

const initialState: State = {
  carQuote: undefined,
};

const reducer = handleActions(
  {
    [String(stateUpdate)]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  initialState,
);

export default reducer;
