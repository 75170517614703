import React from 'react';
import { Card, Alert, Button } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import UserFormControl from '@components/Feature/User/UserFormControl';

import { useCarRegistrationPlaces } from '../hook';
import { FormValues } from './schema';
import { useValidationSchema } from './hook';
import { VehicleCardBody } from './vehicle-card-body';

type Props = {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
};

const CarQuoteForm = ({ initialValues, onSubmit }: Props) => {
  const { t } = useTranslation();
  const validationSchema = useValidationSchema();

  useCarRegistrationPlaces();

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    onSubmit(values);
    setSubmitting(false);
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <Alert variant="warning">{t('personalInfo.changeabilityWarningMessage')}</Alert>

          <Card>
            <VehicleCardBody />

            <Card.Body>
              <Card.Title>{t('carQuoteBuyPage.carQuoteBuyForm.ownerTitle')}</Card.Title>
              <UserFormControl validationSchema={validationSchema} />
            </Card.Body>

            <Card.Footer className="bg-white border-0">
              <div className="text-center my-4">
                <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                  <span className="me-2">
                    {t('common:summary')} & {t('common:review')}
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export { CarQuoteForm };
