import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import carInsurance from './car-insurance.png';
import healthInsurance from './health-insurance.png';
import personalAccidentInsurance from './personal-accident-insurance.png';
import cancerInsurance from './cancer-insurance.png';

export const useInsuranceProduct = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      title: t('common:insuranceProducts'),
      items: [
        {
          label: t('common:carInsurance'),
          href: '/car-insurance',
          image: carInsurance as string,
        },
        // {
        //   label: t('common:healthInsurance'),
        //   href: '/health-insurance',
        //   image: healthInsurance,
        // },
        // {
        //   label: t('common:personalAccidentInsurance'),
        //   href: '/personal-accident-insurance',
        //   image: personalAccidentInsurance,
        // },
        // {
        //   label: t('common:cancerInsurance'),
        //   href: '/cancer-insurance',
        //   image: cancerInsurance,
        // },
      ],
    }),
    [],
  );
};
