import { useMemo } from 'react';
import { matchPath, useHref, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  faGripHorizontal,
  faReceipt,
  faFileContract,
  faInfoCircle,
  faCar,
  faFileImage,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { UserRoles } from '@models/UserProfile';
import { compact } from 'lodash';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@components/Auth';

type DashboardItemKey =
  | 'dashboard'
  | 'billing'
  | 'policy'
  | 'claim'
  | 'vehicle'
  | 'document'
  | 'password'
  | 'personalInfo'
  | 'clientPolicy';

type DashboardItem = {
  key: DashboardItemKey;
  label: string;
  href: string;
  icon: IconDefinition;
};

export const useUserDashboard = () => {
  const { t } = useTranslation();
  const isFrontline = useSelector(selectCurrentUser)?.role === UserRoles.Frontline;
  const dashboardHref = useHref('/user-dashboard');
  const billingHref = useHref('/user-dashboard/billing');
  const policyHref = useHref('/user-dashboard/policy');
  const claimHref = useHref('/user-dashboard/claim');
  const vehicleHref = useHref('/user-dashboard/vehicle');
  const documentHref = useHref('/user-dashboard/document');
  const passwordHref = useHref('/user-dashboard/password');
  const personalInfoHref = useHref('/user-dashboard/personal-info');
  const clientPolicyHref = useHref('/user-dashboard/client-policy');

  const items: DashboardItem[] = useMemo(() => {
    return compact([
      {
        key: 'dashboard',
        label: t('common:dashboard'),
        href: dashboardHref,
        icon: faGripHorizontal,
      },
      {
        key: 'billing',
        label: t('common:billing'),
        href: billingHref,
        icon: faReceipt,
      },
      {
        key: 'policy',
        label: t('common:policy'),
        href: policyHref,
        icon: faFileContract,
      },
      {
        key: 'claim',
        label: t('common:claim'),
        href: claimHref,
        icon: faFileContract,
      },
      {
        key: 'vehicle',
        label: t('common:vehicle'),
        href: vehicleHref,
        icon: faCar,
      },
      {
        key: 'document',
        label: t('common:document'),
        href: documentHref,
        icon: faFileImage,
      },
      {
        key: 'password',
        label: t('common:password'),
        href: passwordHref,
        icon: faFileContract,
      },
      {
        key: 'personalInfo',
        label: t('common:personalInfo'),
        href: personalInfoHref,
        icon: faInfoCircle,
      },
      isFrontline && {
        key: 'clientPolicy',
        label: t('common:clientPolicy'),
        href: clientPolicyHref,
        icon: faFileContract,
      },
    ]);
  }, [isFrontline]);

  return {
    items,
  };
};

export const useActiveKey = (): DashboardItemKey => {
  const { pathname } = useLocation();
  if (matchPath('/user-dashboard/policy/*', pathname)) return 'policy';
  if (matchPath('/user-dashboard/billing/*', pathname)) return 'billing';
  if (matchPath('/user-dashboard/claim/*', pathname)) return 'claim';
  if (matchPath('/user-dashboard/vehicle/*', pathname)) return 'vehicle';
  if (matchPath('/user-dashboard/document/*', pathname)) return 'document';
  if (matchPath('/user-dashboard/password/*', pathname)) return 'password';
  if (matchPath('/user-dashboard/personal-info/*', pathname)) return 'personalInfo';
  if (matchPath('/user-dashboard/client-policy/*', pathname)) return 'clientPolicy';
  return 'dashboard';
};
