import React, { useEffect } from 'react';
import { Container, Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useHref } from 'react-router-dom';
import { Pagination } from '@components/Base';
import { useSearchParams } from 'react-router-dom';
import { selectArticlePage } from './selector';
import { articlesFetch } from './action';
import ArticleList from './ArticleList';

const ArticlePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { articles, pagination } = useSelector(selectArticlePage);

  useEffect(() => {
    dispatch(articlesFetch({ page: searchParams.get('page') }));
  }, []);

  return (
    <>
      <HeaderPrimary />
      <Container>
        <Breadcrumb className="mt-5">
          <Breadcrumb.Item href={useHref('/')}>{t('home', { ns: 'common' })}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('articles', { ns: 'common' })}</Breadcrumb.Item>
        </Breadcrumb>

        <h1 className="mt-5 mb-5">{t('articles', { ns: 'common' })}</h1>
        <ArticleList articles={articles} />
        {pagination && (
          <div className="my-5 d-flex justify-content-center justify-content-lg-start">
            <Pagination url="/articles" page={pagination?.page ?? 1} totalPages={pagination?.totalPages ?? 1} />
          </div>
        )}
      </Container>
      <Footer />
    </>
  );
};

export { ArticlePage };
