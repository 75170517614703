import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParseParams } from '@hooks/search-params';

import { selectCarQuoteDetailsRoute } from './selector';
import { carQuoteFetch, CarQuoteFetchPayload } from './action';
import { carQuoteDetailsPageParamsSchema, CarQuoteDetailsPageParams } from './route';

export const useCarQuoteDetailsPageParams = () =>
  useParseParams<CarQuoteDetailsPageParams>(carQuoteDetailsPageParamsSchema);

export const useCarQuote = () => {
  const dispatch = useDispatch();
  const payload: CarQuoteFetchPayload = useCarQuoteDetailsPageParams();
  const { carQuote } = useSelector(selectCarQuoteDetailsRoute);

  useEffect(() => {
    dispatch(carQuoteFetch(payload));
  }, []);

  return carQuote;
};
