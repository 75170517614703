import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUser } from '@components/Auth';

import { CarQuoteSendEmailPayload } from '../action';

export type FormValues = Pick<CarQuoteSendEmailPayload, 'firstName' | 'lastName' | 'email'>;

export const useInitialValues = () => {
  const currentUser = useSelector(selectCurrentUser);

  return useMemo(
    () => ({
      firstName: currentUser?.firstName ?? '',
      lastName: currentUser?.lastName ?? '',
      email: currentUser?.email ?? '',
    }),
    [currentUser],
  );
};
