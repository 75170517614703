import { array, number, object, string } from 'yup';
import { UserFormControlValues, userFormControlValuesParamsSchema } from '@components/Feature/User/UserFormControl';

export type MeFormValues = Omit<UserFormControlValues, 'weight' | 'height' | 'bmi'> & {
  medicalQuestionnaire: {
    question: string;
    answer: 'yes' | 'no';
  }[];
};

export type MemberFormValues = Omit<MeFormValues, 'gender' | 'email' | 'mobileNumber' | 'address'>;

export type FormValues = {
  me: MeFormValues;
  spouse?: MemberFormValues;
  sons?: MemberFormValues[];
  daughters?: MemberFormValues[];
  cancerPlanId: number | '';
  isConfirmedDeclaration: boolean;
};

export const meFormValuesParamsSchema = userFormControlValuesParamsSchema.omit(['weight', 'height', 'bmi']).concat(
  object({
    medicalQuestionnaire: array()
      .of(
        object({
          question: string().default(''),
          answer: string().default(''),
        }),
      )
      .default([]),
  }),
);

const memberFormValuesParamsSchema = meFormValuesParamsSchema.omit(['gender', 'email', 'mobileNumber', 'address']);

export const formValuesParamsSchema = object({
  me: meFormValuesParamsSchema,
  spouse: memberFormValuesParamsSchema.default(undefined),
  sons: array().of(memberFormValuesParamsSchema).default(undefined),
  daughters: array().of(memberFormValuesParamsSchema).default(undefined),
  cancerPlanId: number().default(undefined),
});
