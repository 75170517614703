import { createActions } from 'redux-actions';

import { CarQuote } from '@models/CarQuote';
import { Car } from '@models/Car';
import { GetCarParamsWithCarId } from '@apis/CarApi';
import { GetAllCarQuoteParams } from '@apis/CarQuoteApi';

export type State = {
  car: Car | undefined;
  carQuotes: CarQuote[] | null | undefined;
  comparableCarQuotes: CarQuote[];
};

export type CarFetchPayload = GetCarParamsWithCarId;
export type CarSetPayload = { car: Car };

export type CarQuotesFetchPayload = Required<Omit<GetAllCarQuoteParams, 'carPlanIds'>>;
export type CarQuoteComparableAddPayload = { carQuote: CarQuote };
export type CarQuoteComparableRemovePayload = CarQuoteComparableAddPayload;

export type StateUpdatePayload = Partial<State>;

export const {
  carFetch,
  carSet,

  carQuotesFetch,

  carQuoteComparableAdd,
  carQuoteComparableRemove,
  carQuoteComparableRemoveAll,

  stateUpdate,
  stateReset,
} = createActions(
  'CAR_FETCH',
  'CAR_SET',

  'CAR_QUOTES_FETCH',

  'CAR_QUOTE_COMPARABLE_ADD',
  'CAR_QUOTE_COMPARABLE_REMOVE',
  'CAR_QUOTE_COMPARABLE_REMOVE_ALL',

  'STATE_UPDATE',
  'STATE_RESET',
  {
    prefix: 'CAR_QUOTE_RESULT_PAGE',
  },
);
