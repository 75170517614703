import React, { useMemo } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserAvatar } from '@components/Feature/User/user-avatar';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormGroupControl } from '@components/Base';
import { ServerError } from '@components/Helper';
import { VerifyWithPasswordAuthParams } from '@apis/AuthApi';
import { passwordVerify } from './action';
import { selectPassword } from './selector';

export type FormValues = VerifyWithPasswordAuthParams;

const PasswordCard = () => {
  const { t } = useTranslation();
  const { mobileNumber } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errors } = useSelector(selectPassword);

  const initialValues: FormValues = useMemo(
    () => ({
      mobileNumber: mobileNumber || '',
      password: '',
    }),
    [],
  );
  const validationSchema = useMemo(
    () =>
      Yup.object({
        password: Yup.string().required(t('required', { ns: 'validation' })),
      }),
    [],
  );
  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    dispatch(passwordVerify(values));
    setSubmitting(false);
  };

  return (
    <Card>
      <Card.Header className="text-center">
        <UserAvatar />
      </Card.Header>
      <Card.Body>
        <Card.Title>{t('registration.password.title')}</Card.Title>
        <Card.Text>
          {t('registration.password.title')}
          &nbsp;
          <strong>{mobileNumber}</strong>
        </Card.Text>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <FormGroupControl
                type="password"
                name="password"
                label={t('common:password')}
                groupProps={{ className: 'mb-3' }}
              />

              <div className="d-grid gap-2">
                <Button type="submit" disabled={isSubmitting} variant="primary">
                  {t('common:signIn')}
                </Button>
                <Button variant="outline-secondary" onClick={() => navigate(-1)}>
                  {t('common:enterWrongNumber')}
                </Button>
              </div>
              <ServerError errors={errors} />
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export { PasswordCard };
