import React from 'react';
import { useFormikContext } from 'formik';
import { map } from 'lodash';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';
import { Button, Accordion, Card, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { tOrdinalItem } from '@components/i18n';
import { FormGroupCheck, StepFormProps } from '@components/Base';

import { FormValues } from './schema';
import { ContactFormControl } from './contact-form-control';
import { selectHealthQuoteBuyRoute } from '../selector';

const ContactStepForm = ({ onNext }: StepFormProps) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValues>();
  const { healthQuote } = useSelector(selectHealthQuoteBuyRoute);

  return (
    <Card>
      <Card.Body>
        <Accordion defaultActiveKey="me">
          <ContactFormControl
            name="me"
            label={`${t('common:myself')} (${values.me.age} ${pluralize(t('common:year'), Number(values.me.age))})`}
          />

          {!!values.spouse && (
            <ContactFormControl
              name="spouse"
              label={`${t('common:spouse')} (${values.spouse.age} ${pluralize(
                t('common:year'),
                Number(values.spouse.age),
              )})`}
            />
          )}

          {!!values.sons &&
            map(values.sons, (son, idx) => (
              <ContactFormControl
                key={`sons.${idx}`}
                name={`sons.${idx}`}
                label={`${tOrdinalItem(idx + 1, 'common:son')} (${son.age} ${pluralize(
                  t('common:year'),
                  Number(son.age),
                )})`}
              />
            ))}

          {!!values.daughters &&
            map(values.daughters, (daughter, idx) => (
              <ContactFormControl
                key={`daughters.${idx}`}
                name={`daughters.${idx}`}
                label={`${tOrdinalItem(idx + 1, 'common:daughter')} (${daughter.age} ${pluralize(
                  t('common:year'),
                  Number(daughter.age),
                )})`}
              />
            ))}
        </Accordion>
      </Card.Body>

      {
        healthQuote?.productDocument && <Card.Body>
          <Card.Title className="text-warning">
            {t('common:declaration')}
          </Card.Title>

          <Alert variant="warning" className="mb-0">
            <FormGroupCheck
              name='isConfirmedDeclaration'
              label={healthQuote?.productDocument?.occupationDeclarationDescription}
            />
          </Alert>
        </Card.Body>
      }

      <Card.Footer>
        <div className="d-grid">
          <Button disabled={!values.me.occupation || !values.isConfirmedDeclaration} type="submit" onClick={onNext} size="lg">
            {t('common:next')}
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export { ContactStepForm };
