import { object, number } from 'yup';
import { stringify } from 'qs';

import { carQuoteResultPageParamsSchema, CarQuoteResultPageParams } from '@components/CarQuoteResultPage/route';

export type CarQuoteDetailsPageParams = Required<CarQuoteResultPageParams> & {
  carPlanId: number;
};

export type CarQuoteSendEmailPageParams = CarQuoteDetailsPageParams;

export const carQuoteDetailsPageParamsSchema = carQuoteResultPageParamsSchema.concat(
  object({
    carPlanId: number(),
  }),
);

export const carQuoteSendEmailPageParamsSchema = carQuoteDetailsPageParamsSchema;

export const CAR_QUOTE_DETAILS_ROUTE_URL = '/car-insurance-quote-details/*';

export const CAR_QUOTE_DETAILS_PAGE_URL = '/car-insurance-quote-details';

export const CAR_QUOTE_SEND_MAIL_PAGE = '/car-insurance-quote-details/send-email';

export const getCarQuoteDetailsPageRoute = (params: CarQuoteDetailsPageParams) => {
  return {
    pathname: CAR_QUOTE_DETAILS_PAGE_URL,
    search: stringify(params),
  };
}

export const getCarQuoteSendEmailPageRoute = (params: CarQuoteSendEmailPageParams) => {
  return {
    pathname: CAR_QUOTE_SEND_MAIL_PAGE,
    search: stringify(params),
  };
}
