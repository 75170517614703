import { call, put, takeLatest } from 'redux-saga/effects';

import { CarQuote } from '@models/CarQuote';
import { CarQuoteApi } from '@apis/CarQuoteApi';
import { SagaPayload } from '@utils/typescript';

import {
  carQuoteFetch,
  CarQuoteFetchPayload,
  carQuoteSendEmail,
  CarQuoteSendEmailPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleCarQuoteFetch({ payload }: SagaPayload<CarQuoteFetchPayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.get, payload)) as CarQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarQuoteSendEmail({ payload }: SagaPayload<CarQuoteSendEmailPayload>) {
  yield call(CarQuoteApi.sendEmail, payload);
}

export default function* () {
  yield takeLatest(carQuoteFetch, handleCarQuoteFetch);
  yield takeLatest(carQuoteSendEmail, handleCarQuoteSendEmail);
}
