import React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Card, Button, ListGroup, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isEmpty, map, some } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { tOrdinalItem } from '@components/i18n';
import { StepFormProps } from '@components/Base';

import { FormValues } from '../schema';
import { CardHeader } from './card-header';
import { MemberList } from './member-list';
import { ErrorItem } from './error-item';
import { MedicalQuestionnaire } from '@apis/CancerQuoteApi';

import { selectCancerQuoteBuyRoute } from '../../selector';

const QuestionForm = ({ onPrev }: StepFormProps) => {
  const { t } = useTranslation();
  const { values, isValid, errors, touched } = useFormikContext<FormValues>();
  const { cancerQuote } = useSelector(selectCancerQuoteBuyRoute);

  const shouldRenderErrorItem = (questionaires: MedicalQuestionnaire[]) =>
    some(questionaires, ['answer', 'yes']) || some(questionaires, ['answer', 'no']);

  return (
    <Card>
      <CardHeader onClick={onPrev} title="" />
      <Card.Body className="d-grid gap-3">
        {map(cancerQuote?.cancerPlanQuestions, ({ question }, idx) => (
          <Card key={`${question}-${idx}`} data-testid="question-item">
            <Card.Body>
              <p>{question}</p>
              <MemberList questionIdx={idx} />
            </Card.Body>
          </Card>
        ))}

        {!isEmpty(errors) && !isEmpty(touched) && (
          <Alert variant="danger">
            <Alert.Heading>
              <FontAwesomeIcon icon={faInfoCircle} className="fa-fw me-2" />
              {t('cancerQuoteBuyPage.medicalQuestions.error.title')}
            </Alert.Heading>

            <p>{t('cancerQuoteBuyPage.medicalQuestions.error.description')}</p>
          </Alert>
        )}

        {!isEmpty(errors) && (
          <>
            <ListGroup as="ol" numbered data-testid="question-errors-list">
              {!!errors.me && shouldRenderErrorItem(values.me.medicalQuestionnaire) && (
                <ErrorItem removable={false} name="me" label={t('common:myself')} />
              )}
              {!!errors.spouse && shouldRenderErrorItem(values.spouse?.medicalQuestionnaire ?? []) && (
                <ErrorItem name="spouse" label={t('common:spouse')} />
              )}

              {!!errors.sons &&
                map(
                  values.sons,
                  (son, idx) =>
                    shouldRenderErrorItem(son.medicalQuestionnaire) && (
                      <ErrorItem key={`sons${idx}`} name={`sons[${idx}]`} label={tOrdinalItem(idx + 1, 'common:son')} />
                    ),
                )}
              {!!errors.daughters &&
                map(
                  values.daughters,
                  (daughter, idx) =>
                    shouldRenderErrorItem(daughter.medicalQuestionnaire) && (
                      <ErrorItem
                        key={`daughters${idx}`}
                        name={`daughters[${idx}]`}
                        label={tOrdinalItem(idx + 1, 'common:daughter')}
                      />
                    ),
                )}
            </ListGroup>
          </>
        )}

        <Button size="lg" type="submit" disabled={!isValid}>
          {t('common:summary')} & {t('common:review')}
        </Button>
      </Card.Body>
    </Card>
  );
};

export { QuestionForm };
