import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectCurrentUser } from '@components/Auth';
import { UserRoles } from '@models/UserProfile';

import InsuredDeclaredValue from './InsuredDeclaredValue';
import PlanType from './PlanType';
import { FilterAddonCover } from './filter-addon-cover';
import { Promotion } from './promotion';

const FilterSection = () => {
  const currentUser = useSelector(selectCurrentUser);
  const shouldRenderCoupon = currentUser?.role === UserRoles.Frontline;

  return (
    <Container className="shadow mt-3 mb-5 rounded">
      <Row>
        <Col lg={3}>
          <InsuredDeclaredValue />
        </Col>
        <Col lg={3}>
          <PlanType />
        </Col>
        <Col lg={6}>
          <FilterAddonCover />
        </Col>
      </Row>

      {shouldRenderCoupon && (
        <Row>
          <Col lg={3}>
            <Promotion />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export { FilterSection };
