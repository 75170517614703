import { tEnum } from '@components/i18n';
import { KeysToCamelCase } from '@utils/typescript';

export enum FuelTypes {
  Unknown = 'unknown',
  Petrol = 'petrol',
  Diesel = 'diesel',
  Hybrid = 'hybrid',
}

export const tFuelType = (fuelType: FuelTypes) => tEnum('FuelTypes', fuelType);

export type CarInfoResponse = {
  car_brand_name: string;
  car_model_name: string;
  car_sub_model_name: string;
  engine_capacity: number;
  manufactured_year: number;
  fuel_type: FuelTypes;
};

export type CarInfo = KeysToCamelCase<CarInfoResponse>;

export type CarResponse = CarInfoResponse & {
  id: number;
  car_brand_id: number;
  car_model_id: number;
  car_sub_model_id: number;
  lowest_amount: number;
  highest_amount: number;
  recommended_amount: number;
  unknown: boolean;
};

export type Car = KeysToCamelCase<CarResponse>;

export const DELIMITER = {
  SPACE: ' ',
  DASH: ' - ',
};

export type CarName = Pick<CarInfo, 'carBrandName' | 'carModelName' | 'carSubModelName'>;

export const getCarName = ({ carBrandName, carModelName, carSubModelName }: CarName, delimiter: string) => {
  let label = `${carBrandName.toString()}${delimiter}${carModelName.toString()}`;

  if (carSubModelName !== 'unknown') {
    label += `${delimiter}${carSubModelName.toString()}`;
  }

  return label;
};
