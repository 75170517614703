import { object, number } from 'yup';

import { CustomCarQuoteParams } from '@apis/CarQuoteApi';

export type FormValues = Omit<CustomCarQuoteParams, 'carOrderId'>;

export const formValuesParamsSchema = object({
  thirdPartyAmount: number(),
  thirdPartyDiscountAmount: number(),

  thirdPartyDeductibleAmount: number(),
  thirdPartyDeductibleDiscountAmount: number(),

  ownDamageAmount: number(),
  ownDamageDiscountAmount: number(),

  ownDamageDeductibleAmount: number(),
  ownDamageDeductibleDiscountAmount: number(),

  theftDeductibleAmount: number(),
  theftDeductibleDiscountAmount: number(),

  accidentToDriverAmount: number(),
  accidentToDriverDiscountAmount: number(),

  passengerLiabilityAmount: number(),
  passengerLiabilityDiscountAmount: number(),

  adminFeeAmount: number(),
  adminFeeDiscountAmount: number(),

  noClaimDiscountPercentage: number(),
  loyaltyDiscountPercentage: number(),
});
