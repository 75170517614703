import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { some, lowerCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, Card, ToggleButton, Stack } from 'react-bootstrap';

import { CarQuote } from '@models/CarQuote';
import { amountToUsd } from '@utils/currency';
import { useLgScreen } from '@hooks/screen';
import { CarPlanTypes } from '@models/CarPlanType';
import { InsurerLogo } from '@components/Feature/Insurer';
import { getCarQuoteDetailsPageRoute } from '@components/CarQuoteDetailsRoute/route';

import {
  carQuoteComparableAdd,
  CarQuoteComparableAddPayload,
  carQuoteComparableRemove,
  CarQuoteComparableRemovePayload,
} from '../action';
import { selectCarQuoteResultPage } from '../selector';
import { useCarQuotesResultPageParams } from '../hook';
import { CarQuoteDetailsModal } from './car-quote-details-modal';

type Props = {
  carQuote: CarQuote;
};

const CarQuoteItem = ({ carQuote }: Props) => {
  const { planType, insurer, totalPremium, garages, idvAmount, carPlanId } = carQuote;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLgScreen = useLgScreen();
  const params = useCarQuotesResultPageParams();
  const { comparableCarQuotes } = useSelector(selectCarQuoteResultPage);
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleCompare = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    if (currentTarget.checked) {
      const payload: CarQuoteComparableAddPayload = { carQuote };
      dispatch(carQuoteComparableAdd(payload));
    } else {
      const payload: CarQuoteComparableRemovePayload = { carQuote };
      dispatch(carQuoteComparableRemove(payload));
    }
  };

  const handleDetails = () => {
    if (isLgScreen) {
      setShow(true);
      return;
    }

    navigate(getCarQuoteDetailsPageRoute({ ...params, carPlanId }));
  };

  useEffect(() => {
    setChecked(some(comparableCarQuotes, (comparableCarQuote) => comparableCarQuote.carPlanId === carPlanId));
  }, [comparableCarQuotes]);

  return (
    <>
      <Card bg="light" className="shadow" border="light" data-testid="car-quote-item">
        <Card.Body>
          <div className="d-flex align-items-center justify-content-center mb-3" style={{ height: 120 }}>
            <InsurerLogo insurer={insurer} fluid rounded />
          </div>

          {planType === CarPlanTypes.Comprehensive && (
            <Card.Text className="text-center text-capitalize">
              {t('carInsuranceQuote.coverValueIdv')}: {amountToUsd(idvAmount)}
            </Card.Text>
          )}

          <div className="d-grid">
            <Button variant="outline-primary" onClick={handleDetails} size="lg">
              <h5 className="text-uppercase text-decoration-underline">{t('carInsuranceQuote.buyNow')}</h5>
              <h4 className="m-0">
                {amountToUsd(totalPremium)}/{lowerCase(t('common:year'))}
              </h4>
            </Button>
          </div>

          {planType === CarPlanTypes.Comprehensive && (
            <Card.Text className="text-center mt-3 text-capitalize">
              {t('carInsuranceQuote.garagesCount', { garagesCount: garages.length })}
            </Card.Text>
          )}
        </Card.Body>

        <Card.Footer className="bg-white py-3">
          <Stack direction="horizontal" gap={2} className="d-flex justify-content-center">
            <ToggleButton
              id={`quote-${insurer.id}`}
              type="checkbox"
              variant="outline-secondary"
              value={insurer.id}
              checked={checked}
              onChange={handleCompare}
              className="d-none d-lg-block w-100"
            >
              {t('common:compare')}
            </ToggleButton>

            <div className="vr d-none d-lg-block" />

            <Button variant="outline-primary" onClick={handleDetails} className="w-100">
              {t('common:preview')}
            </Button>
          </Stack>
        </Card.Footer>
      </Card>

      <CarQuoteDetailsModal carQuote={carQuote} show={show} onHide={() => setShow(false)} />
    </>
  );
};

export { CarQuoteItem };
