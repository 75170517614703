import { object, date, string } from 'yup';

import { BypassCarQuoteParams } from '@apis/CarQuoteApi';

export type FormValues = Omit<BypassCarQuoteParams, 'carOrderId'>;

export const formValuesParamsSchema = object({
  activationDate: date(),
  frontlineNote: string(),
});
