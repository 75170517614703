import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ConfirmModal } from '@components/Base';
import { getCarName, DELIMITER } from '@models/Car';

import { useCarFetch } from '../hook';

const SubHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const car = useCarFetch();
  const [show, setShow] = useState(false);

  return (
    <>
      <section className="py-3">
        <Container className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="me-5 fs-6">
              {car && <span className="d-block">{getCarName(car, DELIMITER.DASH)}</span>}
              <small>
                {t('common:privateCar')} . {car?.manufacturedYear}
              </small>
            </div>

            <Button variant="outline-primary" onClick={() => setShow(true)}>
              {t('common:change')}
            </Button>
          </div>
          {/* TODO: Show when NBC (No Claim Bonus) feature is ready
            <div className="small text-black-50">
              NBC
              <FontAwesomeIcon icon={faInfo} className="mx-2" />
              is set to 0%
              <FontAwesomeIcon icon={faAngleDown} className="ms-2" />
            </div> */}
        </Container>
      </section>

      <ConfirmModal show={show} onHide={() => setShow(false)} onConfirm={() => navigate('/car-insurance-quote')} />
    </>
  );
};

export { SubHeader };
