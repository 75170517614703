import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { history } from '@utils/history';
import { getResponseError } from '@utils/error';
import { SagaPayload } from '@utils/typescript';
import { AuthApi } from '@apis/AuthApi';
import { UserProfile } from '@models/UserProfile';
import { currentUserSet } from '@components/Auth';
import { toastShow } from '@components/ToastProvider';
import i18n from '@components/i18n';

import { userUpdate, stateUpdate } from './action';
import { StateUpdatePayload, UserUpdatePayload } from './type';

function* handleUserUpdate({ payload }: SagaPayload<UserUpdatePayload>) {
  try {
    const currentUser = (yield call(AuthApi.updateUserProfile, payload)) as UserProfile;
    yield put(currentUserSet(currentUser));
    history.navigate(`/user-dashboard/personal-info`, { replace: true });
    yield put(toastShow({ status: 'success', body: i18n.t('toast.personalInfo.updateSuccess') }));
  } catch (e) {
    const state: StateUpdatePayload = {};
    state.errors = getResponseError(e as AxiosError).errors;
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(userUpdate, handleUserUpdate);
}
