import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HeaderSecondary from '@components/HeaderSecondary';

import ComparableCarQuote from './ComparableCarQuote';
import SubHeader from './SubHeader';
import CarQuoteList from './CarQuoteList';
import FilterSection from './FilterSection';
import FilterProvider from './FilterProvider';
import { stateReset } from './action';

const CarQuoteResultPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(stateReset());
  }, []);

  return (
    <>
      <HeaderSecondary />
      <SubHeader />
      <FilterProvider>
        <FilterSection />
        <CarQuoteList />
        <ComparableCarQuote />
      </FilterProvider>
    </>
  );
};

export { CarQuoteResultPage };
