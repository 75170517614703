import React from 'react';
import { getUserCarStatusText, getUserCarStatusVariant, UserCarStatuses } from '@models/UserCar';
import { Badge } from 'react-bootstrap';

const VehicleBadge = ({ status }: { status: UserCarStatuses }) => {
  const variant = getUserCarStatusVariant(status);
  const text = getUserCarStatusText(status);

  return (
    <Badge data-testid={`vehicle-badge ${variant}`} bg={variant}>
      {text}
    </Badge>
  );
};

export { VehicleBadge };
