import { put, call, takeLatest } from 'redux-saga/effects';

import { Car } from '@models/Car';
import { CarApi } from '@apis/CarApi';
import { history } from '@utils/history';
import { CarQuoteApi } from '@apis/CarQuoteApi';
import { CarQuote } from '@models/CarQuote';
import { SagaPayload } from '@utils/typescript';

import {
  carFetch,
  CarFetchPayload,
  carSet,
  CarSetPayload,
  carQuotesFetch,
  CarQuotesFetchPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';

function* handleCarFetch({ payload }: SagaPayload<CarFetchPayload>) {
  try {
    const car = (yield call(CarApi.getIdv, payload)) as Car;
    const carSetPayload: CarSetPayload = { car };
    yield put(carSet(carSetPayload));
  } catch {
    history.navigate('/car-insurance-quote/car-brand', { replace: true });
  }
}

function* handleCarQuotesFetch({ payload }: SagaPayload<CarQuotesFetchPayload>) {
  const state: StateUpdatePayload = { carQuotes: null };
  try {
    state.carQuotes = (yield call(CarQuoteApi.getAll, payload)) as CarQuote[];
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(carFetch, handleCarFetch);
  yield takeLatest(carQuotesFetch, handleCarQuotesFetch);
}
