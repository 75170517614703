import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { amountToUsd } from '@utils/currency';
import { InsurerLogo } from '@components/Feature/Insurer';
import { useCancerQuoteResultPageParams } from '@components/CancerQuoteResultPage';
import { getCancerQuoteBuyNewPageRoute } from '@components/CancerQuoteBuyRoute/route';

import { selectCancerQuoteComparePage } from './selector';

const TableInsurer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useCancerQuoteResultPageParams();
  const { cancerQuotes } = useSelector(selectCancerQuoteComparePage);

  const handleBuy = (cancerPlanId: number) => {
    navigate(getCancerQuoteBuyNewPageRoute({ ...params, cancerPlanId }));
  };

  return (
    <tr className="shadow-sm sticky-top table-light">
      <th className="w-25 py-4" />
      {map(cancerQuotes, ({ cancerPlanId, insurer, totalPremium, cancerPlanName }) => (
        <th key={cancerPlanId} className="py-4">
          <div className="d-flex justify-content-center align-items-center mb-1" style={{ width: 200, height: 50 }}>
            <InsurerLogo insurer={insurer} rounded fluid />
          </div>

          <div className="mb-4">({cancerPlanName})</div>

          <Button onClick={() => handleBuy(cancerPlanId)} variant="primary" size="lg" style={{ width: 200 }}>
            <small className="text-capitalize">{t('common:buyNow')}</small>
            <span className="fw-bold d-block">{amountToUsd(totalPremium)}</span>
          </Button>
        </th>
      ))}
    </tr>
  );
};

export { TableInsurer };
