import { call, put, takeLatest } from 'redux-saga/effects';

import { CarOrder } from '@models/CarOrder';
import { CarOrderApi } from '@apis/CarOrderApi';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';
import { getCarOrderPaymentPageRoute } from '@components/CarOrderPaymentPage/route';

import {
  stateUpdate,
  StateUpdatePayload,
  carOrderPreview,
  CarOrderPreviewPayload,
  carOrderRenew,
  CarOrderRenewPayload,
} from './action';

function* handleCarOrderPreview({ payload }: SagaPayload<CarOrderPreviewPayload>) {
  const state: StateUpdatePayload = { carOrder: null };
  try {
    state.carOrder = (yield call(CarOrderApi.preview, payload)) as CarOrder;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarOrderRenew({ payload }: SagaPayload<CarOrderRenewPayload>) {
  const state: StateUpdatePayload = { carOrder: null };
  try {
    state.carOrder = (yield call(CarOrderApi.renew, payload)) as CarOrder;
    yield put(stateUpdate(state));
    history.navigate(
      getCarOrderPaymentPageRoute({ carOrderId: state.carOrder.id, billingId: state.carOrder.billingId }),
    );
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(carOrderPreview, handleCarOrderPreview);
  yield takeLatest(carOrderRenew, handleCarOrderRenew);
}
