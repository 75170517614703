import { range, reverse, map } from 'lodash';
import moment from 'moment-timezone';
import { MOMENT_TIMEZONE } from '@config';

export enum DATE_FORMAT {
  SHORT = 'll', // Dec 1, 2023 | Dec 21, 2023
  LONG = 'LL', // December 1, 2003 | December 21, 2023
}

export enum DATETIME_FORMAT {
  SHORT = 'lll', // Oct 13, 2022 10:59 AM
  LONG = 'MMMM DD, YYYY hh:mm:ss A', // October 13, 2022 10:59:59 AM
}

export const getOptionYears = () => {
  const startYear = 1990;
  const endYear = new Date().getFullYear();

  return map(reverse(range(startYear, endYear + 2)), (year) => year.toString());
};

export const formatDate = (date?: string, format = DATE_FORMAT.SHORT) => {
  if (!date) return '';

  return moment(date).format(format);
};

export const formatDateTime = (timestamp?: number, format = DATETIME_FORMAT.SHORT) => {
  if (!timestamp) return '';

  return moment.unix(timestamp).tz(MOMENT_TIMEZONE).format(format);
};

export const getAge = (value: string) => {
  const date = moment(value);

  if (!date.isValid()) return;
  return moment().diff(date, 'years');
};

export const getCurrentYear = () => moment().tz(MOMENT_TIMEZONE).year();
