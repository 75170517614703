import React from 'react';
import { useField } from 'formik';
import { ToggleButton, Button, ButtonGroup } from 'react-bootstrap';
import { dropRight, isEmpty } from 'lodash';

import { FormValuesContact } from './type';

type Props = {
  name: string;
  label: string;
  max: number;
};

const FormikToggleButtonWithCount = ({ name, label, max }: Props) => {
  const [field, , helpers] = useField(name);
  const values = field.value as FormValuesContact[];
  const isChecked = !isEmpty(values);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.currentTarget.checked ? [{ checked: true }] : []);
  };

  const handleAdd = () => {
    helpers.setValue([...values, { checked: true }]);
  };

  const handleRemove = () => {
    helpers.setValue(dropRight(values));
  };

  return (
    <>
      <ToggleButton
        {...field}
        id={name}
        checked={isChecked}
        onChange={handleChange}
        type="checkbox"
        variant="outline-primary"
        className="btn-lg w-100 text-nowrap"
      >
        {label}
      </ToggleButton>

      {isChecked && (
        <div style={{ bottom: '10px' }} className="bg-white position-absolute">
          <ButtonGroup size="sm">
            <Button variant="outline-secondary" onClick={handleRemove}>
              -
            </Button>
            <Button disabled variant="secondary">
              {values.length}
            </Button>
            <Button variant="outline-secondary" disabled={values.length === max} onClick={handleAdd}>
              +
            </Button>
          </ButtonGroup>
        </div>
      )}
    </>
  );
};

export { FormikToggleButtonWithCount };
