import { array, boolean, object, string, lazy } from 'yup';

import { Genders } from '@models/User';

export type FormValues = {
  me: {
    checked: boolean,
    dob: string,
    age: number | '',
    gender: string,
  };
  spouse: {
    checked: boolean,
    dob: string,
    age: number | '',
  };
  sons: {
    dob: string,
    age: number | '',
  }[];
  daughters: {
    dob: string,
    age: number | '',
  }[];
};

export const formValuesParamsSchema = object({
  me: object({
    checked: boolean().default(true),
    dob: string().default(''),
    age: string().default(''),
    gender: string().default(Genders.Male),
  }),
  spouse: object({
    checked: lazy((value, { parent }) => boolean().default(!!parent.dob)),
    dob: string().default(''),
    age: string().default(''),
  }),
  sons: array().of(
    object({
      dob: string().default(''),
      age: string().default(''),
    })
  ).default(undefined),
  daughters: array().of(
    object({
      dob: string().default(''),
      age: string().default(''),
    })
  ).default(undefined),
});
