import { object, string } from 'yup';
import { Genders, User } from '@models/User';

export type UserFormControlValues = Omit<User, 'id' | 'fullName' | 'fullNameKm'> & {
  age: number | '';
  weight: number | '';
  height: number | '';
  bmi: number | '';
};

export const userFormControlValuesParamsSchema = object({
  firstName: string().default(''),
  firstNameKm: string().default(''),
  lastName: string().default(''),
  lastNameKm: string().default(''),
  dob: string().default(''),
  age: string().default(''),
  gender: string().default(Genders.Male),
  weight: string().default(''),
  height: string().default(''),
  bmi: string().default(''),
  personalCode: string().default(''),
  occupation: string().default(''),
  email: string().default(''),
  mobileNumber: string().default(''),
  address: string().default(''),
})
