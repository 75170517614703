import React, { useCallback, useMemo } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { UserAvatar } from '@components/Feature/User/user-avatar';
import OtpCodeInputGroup, { useOtpCodeSchema } from '@components/OtpCodeInputGroup';
import { useDispatch, useSelector } from 'react-redux';
import { ServerError } from '@components/Helper';
import { VerifyWithOtpCodeAuthParams } from '@apis/AuthApi';
import { otpCodeRequest, otpCodeVerify } from './action';
import { selectOtpCode } from './selector';

export type FormValues = VerifyWithOtpCodeAuthParams;

const OtpCodeCard = () => {
  const { t } = useTranslation();
  const { mobileNumber } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const otpCodeSchema = useOtpCodeSchema();
  const { errors } = useSelector(selectOtpCode);

  const initialValues: FormValues = useMemo(
    () => ({
      mobileNumber: mobileNumber || '',
      otpCode: '',
    }),
    [],
  );
  const validationSchema = useMemo(
    () =>
      Yup.object({
        otpCode: otpCodeSchema,
      }),
    [],
  );
  const handleSubmit = useCallback((values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    dispatch(otpCodeVerify(values));
    setSubmitting(false);
  }, []);
  const handleOtpCodeRequest = () => {
    dispatch(otpCodeRequest({ mobileNumber }));
  };

  return (
    <Card>
      <Card.Header className="text-center">
        <UserAvatar />
      </Card.Header>
      <Card.Body>
        <Card.Title>{t('registration.otpCode.title')}</Card.Title>
        <Card.Text>
          {t('registration.otpCode.desc')}
          &nbsp;
          <strong>{mobileNumber}</strong>
        </Card.Text>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <OtpCodeInputGroup onRequest={handleOtpCodeRequest} />

              <div className="d-grid gap-2">
                <Button type="submit" disabled={isSubmitting} variant="primary">
                  {t('signIn', { ns: 'common' })}
                </Button>
                <Button variant="outline-secondary" onClick={() => navigate(-1)}>
                  {t('enterWrongNumber', { ns: 'common' })}
                </Button>
              </div>
              <ServerError errors={errors} />
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export { OtpCodeCard };
