import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { map, includes, isNull, isUndefined, toNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHref, useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Card, Image, Row, Col } from 'react-bootstrap';
import { DELIMITER, getCarName, tFuelType } from '@models/Car';
import { UserCarStatuses, UserCarPhotos } from '@models/UserCar';
import { BackButton, Loading, NoData, VehicleBadge } from '@components/Base';
import PhotoManagerDialog from '@components/PhotoManagerDialog';
import { selectVehicleDetails } from './selector';
import { userCarFetch } from './action';

interface RowItemProps {
  label: string;
  value?: string | number | ReactElement;
  list?: string[];
  renderItem?: (item: string) => void;
}

const RowItem = ({ label, value, list, renderItem }: RowItemProps) => {
  return (
    <Row className="mb-2">
      <Col xs={7} sm={7} md={4}>
        {label}
      </Col>
      <Col xs="auto">:</Col>
      <Col>
        <>
          {value && value}
          {list && renderItem && map(list, (item) => renderItem(item))}
        </>
      </Col>
    </Row>
  );
};

const VehicleDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const editHref = useHref('edit');
  const { vehicleId } = useParams();
  const { userCar } = useSelector(selectVehicleDetails);
  const isLoaded = !isUndefined(userCar);
  const hasUserCar = isLoaded && !isNull(userCar);
  const hasPhotos = hasUserCar && userCar.photos.length > 0;
  const shouldRenderRejectedReason = hasUserCar && userCar.status === UserCarStatuses.Rejected;
  const shouldRenderEdit =
    hasUserCar && includes([UserCarStatuses.Unverified, UserCarStatuses.Rejected], userCar.status);

  useEffect(() => {
    dispatch(userCarFetch({ id: vehicleId }));
  }, []);

  const handleBack = useCallback(() => {
    navigate('./../', { replace: true });
  }, []);

  return (
    <>
      <BackButton onClick={handleBack} className="mb-4" />
      {!isLoaded && <Loading />}
      {isLoaded && !hasUserCar && <NoData />}
      {hasUserCar && (
        <Card>
          <Card.Header className="d-flex flex-row justify-content-between align-items-center bg-white">
            <h5 className="mb-0">
              {getCarName(userCar, DELIMITER.DASH)}
            </h5>
            <div>
              {shouldRenderEdit && (
                <Button href={editHref} className="me-3">
                  {t('common:edit')}
                </Button>
              )}
              <Button onClick={() => setShow(true)}>{t('common:managePhotos')}</Button>
            </div>
          </Card.Header>
          <Card.Body>
            <RowItem label={t('common:status')} value={<VehicleBadge status={userCar.status} />} />
            <RowItem label={t('car.brand')} value={userCar.carBrandName} />
            <RowItem label={t('car.model')} value={userCar.carModelName} />
            <RowItem label={t('car.subModel')} value={userCar.carSubModelName} />
            <RowItem label={t('car.yearOfManufacture')} value={userCar.manufacturedYear} />
            <RowItem label={t('car.registrationPlace')} value={userCar.registrationPlace} />
            <RowItem label={t('car.registrationNumber')} value={userCar.registrationNumber} />
            <RowItem label={t('car.chassisNumber')} value={userCar.chassisNumber} />
            <RowItem label={t('car.engineNumber')} value={userCar.engineNumber} />
            <RowItem label={t('car.engineCapacity')} value={userCar.engineCapacity} />
            <RowItem label={t('car.fuelType')} value={tFuelType(userCar.fuelType)} />
            <RowItem
              label={t('car.identificationCard')}
              value={
                userCar.identificationCard ? (
                  <Image data-testid="car-identification-card-image" src={userCar.identificationCard} fluid />
                ) : (
                  t('common:noImage')
                )
              }
            />
            {hasPhotos ? (
              <RowItem
                label={t('common:photos')}
                list={userCar.photos}
                renderItem={(item) => <Image src={item} fluid className="mb-3" />}
              />
            ) : (
              <RowItem label={t('common:photos')} value={t('common:noImage')} />
            )}
            {shouldRenderRejectedReason && (
              <RowItem
                label={t('common:rejectedReason')}
                value={<Alert variant="danger">{userCar.rejectedReason}</Alert>}
              />
            )}

            <PhotoManagerDialog
              {...{
                recordId: toNumber(vehicleId),
                recordType: UserCarPhotos.Type as string,
                name: UserCarPhotos.Name as string,
                limit: UserCarPhotos.Limit as number,
                show,
              }}
              onHide={() => setShow(false)}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export { VehicleDetails };
