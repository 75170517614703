import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Image, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNull, isUndefined, sortBy } from 'lodash';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

import { CarBrand } from '@models/CarBrand';
import { Expand, NoData, Loading } from '@components/Base';
import { StepFormProps } from '@components/Base';

import { selectCarQuotePage } from '../selector';
import { carBrandsFetch, stateUpdate } from '../action';
import { StateUpdatePayload } from '../type';
import { FormValues } from './type';
import { Item } from './item';
import { CardHeader } from './card-header';

export const carBrandValidationSchema = Yup.object({
  carBrandId: Yup.number().required(),
});

const CarBrandStepForm = ({ onNext }: StepFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { carBrands } = useSelector(selectCarQuotePage);
  const [expanded, setExpanded] = useState(false);
  const isLoading = isUndefined(carBrands);
  const isError = isNull(carBrands);
  const isReady = !isLoading && !isError;
  const hasCarBrands = !isEmpty(carBrands);

  const sortedCarBrands = useMemo(() => {
    if (!isReady || !hasCarBrands) return [];
    return expanded ? sortBy(carBrands, 'name') : carBrands;
  }, [isReady, hasCarBrands, expanded, carBrands]);

  const handleItemClick = useCallback((carBrand: CarBrand) => {
    const state: StateUpdatePayload = { carBrand };

    setFieldValue('carBrandId', carBrand.id);
    dispatch(stateUpdate(state));
    onNext?.({ carBrandId: carBrand.id });
  }, []);

  const renderItem = useCallback((carBrand: CarBrand) => {
    const selected = values.carBrandId === carBrand.id;

    return (
      <Col xs={6} sm={4} md={4} lg={4} xl={3} xxl={3} key={`car-brand-${carBrand.id}`} className="mb-3">
        <Item
          testID={classNames('car-brand-item', { selected })}
          selected={selected}
          onClick={() => handleItemClick(carBrand)}
        >
          <Image src={carBrand.logo} fluid alt={carBrand.name} />
          <div className="w-100 pt-2 border-top">{carBrand.name}</div>
        </Item>
      </Col>
    );
  }, []);

  useEffect(() => {
    if (isLoading) {
      dispatch(carBrandsFetch());
    }

    if (values.carBrandId) {
      setExpanded(true);
    }
  }, []);

  return (
    <Card>
      <CardHeader title={t('carInsuranceQuote.carBrand.title')} />
      <Card.Body>
        {isLoading && <Loading />}
        {isReady && !hasCarBrands && <NoData />}
        {isReady && hasCarBrands && (
          <Expand
            expand={expanded}
            wrapper={Row}
            wrapperProps={{ className: 'mb-n3' }}
            items={sortedCarBrands}
            renderItem={renderItem}
            onExpand={() => setExpanded(true)}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export { CarBrandStepForm };
