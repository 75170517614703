import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { selectIsCustomer, selectIsFrontline, selectCurrentUser } from '@components/Auth';
import CarQuotePremiumCardBody from '@components/Feature/CarQuote/CarQuotePremiumCardBody';
import { Loading, CopyLinkButton } from '@components/Base';
import { CarQuoteExclusionCardBody } from '@components/Feature/CarQuote/car-quote-exclusion-card-body';
import { CarQuoteCoverCardBody } from '@components/Feature/CarQuote/car-quote-cover-card-body';
import { CarQuoteDeductibleCardBody } from '@components/Feature/CarQuote/car-quote-deductible-card-body';
import { CarQuoteEndorsementCardBody } from '@components/Feature/CarQuote/car-quote-endorsement-card-body';
import { useParamsCarOrderId } from '@hooks/params';
import { formatDate } from '@utils/datetime';

import { useCarQuoteEdit } from '../hook';
import { carOrderCreate, CarOrderCreateBillingPayload } from '../action';
import { OwnerCardBody } from './owner-card-body';
import { VehicleCardBody } from './vehicle-card-body';
import { getCarQuoteBuyEditPageRoute, getCarQuoteBuyCustomPageRoute, getCarQuoteBuyBypassPageRoute } from '../route';

const CarQuoteReviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const carOrderId = useParamsCarOrderId();
  const carQuote = useCarQuoteEdit();
  const isCustomer = useSelector(selectIsCustomer);
  const isFrontline = useSelector(selectIsFrontline);
  const currentUser = useSelector(selectCurrentUser);

  const handlePay = () => {
    const payload: CarOrderCreateBillingPayload = { id: carOrderId };
    dispatch(carOrderCreate(payload));
  };

  const handleEdit = () => {
    navigate(getCarQuoteBuyEditPageRoute({ carOrderId }));
  };

  const handleCustom = () => {
    navigate(getCarQuoteBuyCustomPageRoute({ carOrderId }));
  };

  const handleBypass = () => {
    navigate(getCarQuoteBuyBypassPageRoute({ carOrderId }));
  };

  return (
    <>
      {isUndefined(carQuote) && <Loading />}

      {carQuote && (
        <Container className="my-3">
          <Row className="gy-5">
            <Col lg={7}>
              <div className="d-flex align-items-center justify-content-sm-end my-3">
                <CopyLinkButton />
              </div>

              <Card>
                <Card.Header>{t('common:summary')}</Card.Header>
                <VehicleCardBody userCar={carQuote.userCar} onEdit={handleEdit} />
                {isFrontline && (
                  <Card.Body>
                    <Card.Title>Frontline details</Card.Title>
                    <ul className="list-unstyled mb-0">
                      <li className="fw-bold">{currentUser?.fullName}</li>
                      <li>
                        {t('car.activationDate')}: {formatDate(carQuote.activationDate)}
                      </li>
                      <li>
                        {t('common:note')}: {carQuote.frontlineNote}
                      </li>
                    </ul>
                  </Card.Body>
                )}
                <OwnerCardBody user={carQuote.user} />
                <CarQuoteCoverCardBody {...carQuote} />
                <CarQuoteExclusionCardBody {...carQuote} />
                <CarQuoteDeductibleCardBody {...carQuote} />
                <CarQuoteEndorsementCardBody {...carQuote} />
              </Card>
            </Col>

            <Col lg={5}>
              <Card>
                <CarQuotePremiumCardBody {...carQuote} />

                {isCustomer && (
                  <Card.Body>
                    <Button onClick={handlePay} className="btn-primary btn-lg w-100" size="lg">
                      <span className="me-2">{t('common:payNow')}</span>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </Card.Body>
                )}

                {isFrontline && (
                  <Card.Body>
                    <div className="d-grid gap-2">
                      <Button onClick={handleCustom} className="btn-primary btn-lg w-100" size="lg">
                        <span className="me-2">Custom Amount</span>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                      <Button onClick={handleBypass} className="btn-primary btn-lg w-100" size="lg">
                        <span className="me-2">Bypass</span>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </div>
                  </Card.Body>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export { CarQuoteReviewPage };
