import React, { useCallback, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Form, Formik, useFormikContext } from 'formik';
import { range, some, pick, pickBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { stringify } from 'qs';

import { FormBodyProps, StepType, useActiveStep } from '@components/Base';

import { carBrandFetch, carFetch, carModelFetch, carVariantFetch } from '../action';
import { CarFetchPayload, CarBrandFetchPayload, CarModelFetchPayload, CarVariantFetchPayload } from '../type';
import { selectCarQuotePage } from '../selector';
import { FormValues } from './type';
import { useFormValuesParams } from './hook';
import { CarBrandStepForm, carBrandValidationSchema } from './car-brand-step-form';
import { CarModelStepForm, carModelValidationSchema } from './car-model-step-form';
import { CarVariantStepForm, carVariantValidationSchema } from './car-variant-step-form';
import { ManufacturedYearStepForm, manufacturedYearValidationSchema } from './manufactured-year-step-form';

const STEPS: StepType[] = [
  {
    pathname: `car-brand`,
    validationSchema: carBrandValidationSchema,
  },
  {
    pathname: `car-model`,
    validationSchema: carModelValidationSchema,
  },
  {
    pathname: `car-variant`,
    validationSchema: carVariantValidationSchema,
  },
  {
    pathname: `manufactured-year`,
    validationSchema: manufacturedYearValidationSchema,
  },
];

const getSearch = (searchValues: FormValues) => {
  return stringify(pickBy(searchValues, (v) => !!v));
};

const FormBody = ({ activeStep }: FormBodyProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { values, validateForm, setValues } = useFormikContext<FormValues>();
  const { carBrand, carModel } = useSelector(selectCarQuotePage);

  const handleNext = (partialValues: Partial<FormValues>) => {
    navigate({
      pathname: STEPS[activeStep + 1].pathname,
      search: getSearch({ ...values, ...partialValues }),
    });
  };

  const handlePrev = (partialValues: Partial<FormValues>) => {
    const newValues = { ...values, ...partialValues };
    setValues(newValues);
    navigate(
      {
        pathname: STEPS[activeStep - 1].pathname,
        search: getSearch(newValues),
      },
      { replace: true },
    );
  };

  useEffect(() => {
    (async () => {
      await validateForm();
    })();
  }, [activeStep]);

  useEffect(() => {
    if (values.carBrandId && values.carBrandId !== carBrand?.id) {
      const payload: CarBrandFetchPayload = { id: values.carBrandId };
      dispatch(carBrandFetch(payload));
    }

    if (values.carModelId && values.carModelId !== carModel?.id) {
      const payload: CarModelFetchPayload = { id: values.carModelId };
      dispatch(carModelFetch(payload));
    }

    if (values.carSubModelId && values.engineCapacity) {
      const payload: CarVariantFetchPayload = pick(values, ['carSubModelId', 'engineCapacity']);
      dispatch(carVariantFetch(payload));
    }
  }, []);

  useEffect(() => {
    some(range(activeStep), (idx) => {
      const { pathname, validationSchema } = STEPS[idx];

      if (validationSchema.isValidSync(values)) return false;

      navigate(
        {
          pathname,
          search: getSearch(values),
        },
        { replace: true },
      );

      return true;
    });
  }, []);

  return (
    <Routes>
      <Route index element={<CarBrandStepForm onNext={handleNext} />} />
      <Route path="car-brand" element={<CarBrandStepForm onNext={handleNext} />} />
      <Route path="car-model" element={<CarModelStepForm onPrev={handlePrev} onNext={handleNext} />} />
      <Route path="car-variant" element={<CarVariantStepForm onPrev={handlePrev} onNext={handleNext} />} />
      <Route path="manufactured-year" element={<ManufacturedYearStepForm onPrev={handlePrev} onNext={handleNext} />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};

const CarQuoteForm = () => {
  const dispatch = useDispatch();
  const activeStep = useActiveStep(STEPS);
  const initialValues = useFormValuesParams();

  const handleSubmit = useCallback((values: FormValues) => {
    const payload: CarFetchPayload = values;
    dispatch(carFetch(payload));
  }, []);

  return (
    <Formik initialValues={initialValues} validationSchema={STEPS[activeStep].validationSchema} onSubmit={handleSubmit}>
      <Form>
        <FormBody activeStep={activeStep} />
      </Form>
    </Formik>
  );
};

export { CarQuoteForm };
