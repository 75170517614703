import { AnySchema } from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { findIndex, last, range, some } from 'lodash';
import { useEffect, useMemo } from 'react';
import { stringify } from 'qs';
import { useFormikContext } from 'formik';

export type StepFormProps = {
  onNext?: () => void;
  onPrev?: () => void;
};

export type StepType = {
  pathname: string;
  validationSchema: AnySchema;
};

export type FormBodyProps = {
  activeStep: number;
  steps: StepType[];
};

export const useActiveStep = (steps?: StepType[]) => {
  const { pathname } = useLocation();
  const activePathname = last(pathname.split('/'));

  return useMemo(() => {
    const idx = findIndex(steps, (options) => options.pathname === activePathname);
    return idx === -1 ? 0 : idx;
  }, [steps, activePathname]);
};

export const useFormBody = ({ steps, activeStep }: FormBodyProps) => {
  const navigate = useNavigate();
  const { initialValues, isValid, validateForm, values, setTouched } = useFormikContext();

  const handleNext = () => {
    if (isValid) {
      setTouched({});
      navigate({
        pathname: steps[activeStep + 1].pathname,
        search: stringify(values),
      });
    }
  };

  const handlePrev = () => {
    navigate(
      {
        pathname: steps[activeStep - 1].pathname,
        search: stringify(values),
      },
      { replace: true },
    );
  };

  useEffect(() => {
    (async () => {
      await validateForm();
    })();
  }, [activeStep]);

  useEffect(() => {
    some(range(activeStep), (idx) => {
      const { pathname, validationSchema } = steps[idx];

      if (validationSchema.isValidSync(initialValues)) return false;

      navigate(
        {
          pathname,
          search: stringify(initialValues),
        },
        { replace: true },
      );

      return true;
    });
  }, [initialValues]);

  return { handleNext, handlePrev };
};
