import React, { useEffect } from 'react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { FormSelect } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { selectCurrentUser } from '@components/Auth';

import { useFilterContext } from '../FilterProvider';

const Promotion = () => {
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const promotionCodes = currentUser?.carPromotionCodes || [];
  const { filters, updateFilters } = useFilterContext();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateFilters({ promotionCode: e.target.value });
  };

  useEffect(() => {
    updateFilters({ promotionCode: '' });
  }, [filters.planType]);

  return (
    <div className="py-2">
      <div className="py-1">
        <span className="pe-1 fw-bold">{t('common:promotionCode')}</span>
      </div>

      <FormSelect onChange={handleChange} name="promotionCode" value={filters.promotionCode}>
        <option></option>
        {map(promotionCodes, (code) => (
          <option key={code} value={code}>
            {code}
          </option>
        ))}
      </FormSelect>
    </div>
  );
};

export { Promotion };
