import { number, object } from 'yup';
import { useParseParams } from '@hooks/search-params';
import { FormValues } from './type';

const schema = object({
  carBrandId: number(),
  carModelId: number(),
  carSubModelId: number(),
  engineCapacity: number(),
  manufacturedYear: number(),
});

export const useFormValuesParams = () => useParseParams<FormValues>(schema);
