import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HeaderSecondary from '@components/HeaderSecondary';

import CarQuoteBuyEditPage from './CarQuoteBuyEditPage';
import CarQuoteBuyNewPage from './CarQuoteBuyNewPage';
import CarQuoteReviewPage from './CarQuoteReviewPage';
import CarQuoteBuyCustomPage from './CarQuoteBuyCustomPage';
import CarQuoteBuyBypassPage from './CarQuoteBuyBypassPage';

const CarQuoteBuyRoute = () => {
  return (
    <>
      <HeaderSecondary />
      <Routes>
        <Route index element={<CarQuoteBuyNewPage />} />
        <Route path="/:carOrderId/edit" element={<CarQuoteBuyEditPage />} />
        <Route path="/:carOrderId/review" element={<CarQuoteReviewPage />} />
        <Route path="/:carOrderId/custom" element={<CarQuoteBuyCustomPage />} />
        <Route path="/:carOrderId/bypass" element={<CarQuoteBuyBypassPage />} />
      </Routes>
    </>
  );
};

export { CarQuoteBuyRoute };
