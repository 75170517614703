import i18n from '@components/i18n';

export enum InsuranceTypes {
  Car = 'car',
  Health = 'health',
  PersonalAccident = 'personal accident',
  Cancer = 'cancer',
}

export const getInsuranceTypeText = (type: InsuranceTypes) => {
  return i18n.t(`insuranceType.${type}`);
};
