import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHref } from 'react-router-dom';
import { getUserCarStatusVariant, UserCar } from '@models/UserCar';
import { DELIMITER, getCarName, tFuelType } from '@models/Car';
import { VehicleBadge } from '@components/Base';
import RowItem from '../RowItem';

interface Props {
  userCar: UserCar;
}

const VehicleItem = ({ userCar }: Props) => {
  const { t } = useTranslation();
  const {
    id,
    status,
    manufacturedYear,
    registrationPlace,
    registrationNumber,
    chassisNumber,
    engineNumber,
    engineCapacity,
    fuelType,
  } = userCar;

  const statusVariant = getUserCarStatusVariant(status);

  return (
    <Card border={statusVariant}>
      <Card.Header className="d-flex flex-row justify-content-between align-items-center bg-white">
        <h5 className="mb-0">
          {getCarName(userCar, DELIMITER.DASH)}
        </h5>
        <VehicleBadge status={status} />
      </Card.Header>
      <Card.Body>
        <RowItem label={t('car.yearOfManufacture')} value={manufacturedYear} />
        <RowItem label={t('car.registrationPlace')} value={registrationPlace} />
        <RowItem label={t('car.registrationNumber')} value={registrationNumber} />
        <RowItem label={t('car.chassisNumber')} value={chassisNumber} />
        <RowItem label={t('car.engineNumber')} value={engineNumber} />
        <RowItem label={t('car.engineCapacity')} value={engineCapacity} />
        <RowItem label={t('car.fuelType')} value={tFuelType(fuelType)} />

        <div className="d-flex flex-row mt-3">
          <Button href={useHref(`${id}`)} variant="outline-primary">
            {t('common:viewDetails')}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export { VehicleItem };
