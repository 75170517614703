import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { object, number } from 'yup';
import { pick } from 'lodash';

import { mergeInitialValues } from '@utils/formik';
import { getFieldKeys } from '@utils/yup';
import { useParseParams } from '@hooks/search-params';
import { CarPlanTypes } from '@models/CarPlanType';

import { selectCarQuoteBuy } from '../selector';
import { FormValues, formValuesParamsSchema } from './schema';

export const useInitialValues = () => {
  const { carQuote } = useSelector(selectCarQuoteBuy);
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);

  return useMemo(() => {
    if (!carQuote) return;

    return mergeInitialValues(initialValues, pick(carQuote, getFieldKeys(formValuesParamsSchema)));
  }, [carQuote]);
};

export const useValidationSchema = () => {
  const { t } = useTranslation();
  const { carQuote } = useSelector(selectCarQuoteBuy);

  if (!carQuote) return null;

  const { planType, accidentToDriver, passengerLiability } = carQuote;

  const schema = object({
    thirdPartyAmount: number().required(t('validation:required')),
    thirdPartyDiscountAmount: number().required(t('validation:required')),

    thirdPartyDeductibleAmount: number().required(t('validation:required')),
    thirdPartyDeductibleDiscountAmount: number().required(t('validation:required')),

    adminFeeAmount: number().required(t('validation:required')),
    adminFeeDiscountAmount: number().required(t('validation:required')),
  });

  if (planType === CarPlanTypes.Comprehensive) {
    schema.concat(
      object({
        ownDamageAmount: number().required(t('validation:required')),
        ownDamageDiscountAmount: number().required(t('validation:required')),

        ownDamageDeductibleAmount: number().required(t('validation:required')),
        ownDamageDeductibleDiscountAmount: number().required(t('validation:required')),

        theftDeductibleAmount: number().required(t('validation:required')),
        theftDeductibleDiscountAmount: number().required(t('validation:required')),
      }),
    );
  }

  if (accidentToDriver) {
    schema.concat(
      object({
        accidentToDriverAmount: number().required(t('validation:required')),
        accidentToDriverDiscountAmount: number().required(t('validation:required')),
      }),
    );
  }

  if (passengerLiability) {
    schema.concat(
      object({
        passengerLiabilityAmount: number().required(t('validation:required')),
        passengerLiabilityDiscountAmount: number().required(t('validation:required')),
      }),
    );
  }

  return schema;
};
