import { createActions } from 'redux-actions';

import { GetCarQuoteParams, SendEmailCarQuoteParams } from '@apis/CarQuoteApi';
import { CarQuote } from '@models/CarQuote';

export type State = {
  carQuote: CarQuote | null | undefined;
};

export type CarQuoteFetchPayload = Required<GetCarQuoteParams>;
export type CarQuoteSendEmailPayload = SendEmailCarQuoteParams;

export type StateUpdatePayload = Partial<State>;

export const {
  carQuoteFetch,
  carQuoteSendEmail,
  stateUpdate,
} = createActions(
  'CAR_QUOTE_FETCH',
  'CAR_QUOTE_SEND_EMAIL',

  'STATE_UPDATE',
  {
    prefix: 'CAR_QUOTE_DETAILS_PAGE',
  },
);
