import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Container, Row } from 'react-bootstrap';

import HealthQuotePremiumCardBody from '@components/HealthQuote/HealthQuotePremiumCardBody';
import { appendHealthPlanQuestionsToFormValues } from '@utils/health-quote';
import { SupportCard } from '@components/Feature/Support/support-card';

import { healthQuoteUpdate, HealthQuoteUpdatePayload } from '../action';
import { useHealthQuoteEdit } from '../hook';
import HealthQuoteBuyForm from '../HealthQuoteBuyForm';
import { FormValues } from '../HealthQuoteBuyForm/schema';
import { useInitialValues } from './hook';

const HealthQuoteBuyEditPage = () => {
  const dispatch = useDispatch();
  const healthQuote = useHealthQuoteEdit();
  const initialValues = useInitialValues();

  const handleSubmit = (values: FormValues) => {
    if (healthQuote) {
      values = appendHealthPlanQuestionsToFormValues(values, healthQuote.healthPlanQuestions);

      const payload: HealthQuoteUpdatePayload = {
        ...values,
        id: healthQuote.healthOrderId,
      };

      dispatch(healthQuoteUpdate(payload));
    }
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          {initialValues && <HealthQuoteBuyForm initialValues={initialValues} onSubmit={handleSubmit} />}
        </Col>
        <Col lg={4}>
          {healthQuote && (
            <Card className="mb-4">
              <HealthQuotePremiumCardBody {...healthQuote} />
            </Card>
          )}
          <SupportCard />
        </Col>
      </Row>
    </Container>
  );
};

export { HealthQuoteBuyEditPage };
