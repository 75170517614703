import { useTranslation } from 'react-i18next';
import { date, object, string } from 'yup';
import 'yup-phone-lite';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    engineNumber: string().required(t('validation:required')),
    chassisNumber: string().required(t('validation:required')),
    registrationPlace: string().required(t('validation:required')),
    registrationNumber: string().required(t('validation:required')),

    firstName: string().required(t('validation:required')),
    lastName: string().required(t('validation:required')),
    firstNameKm: string(),
    lastNameKm: string(),
    gender: string().required(t('validation:required')),
    dob: date(),
    personalCode: string(),
    occupation: string().required(t('validation:required')),
    email: string().email(),
    mobileNumber: string()
      .required(t('validation:required'))
      .phone('KH', t('validation:validPhone', { countryName: t('common:cambodia') })),
    address: string().required(t('validation:required')),
  });
};
