import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Container, Row, Button } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import CarQuotePremiumCardBody from '@components/Feature/CarQuote/CarQuotePremiumCardBody';
import { SupportCard } from '@components/Feature/Support/support-card';
import { FormGroupControl } from '@components/Base';

import { useCarQuoteEdit } from '../hook';
import { carQuoteBypass, CarQuoteBypassPayload } from '../action';
import { FormValues } from './schema';
import { useInitialValues, useValidationSchema } from './hook';

const GROUP_PROPS = { className: 'mb-3' };

const CarQuoteBuyBypassPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const carQuote = useCarQuoteEdit();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  if (!carQuote) return null;

  const { carOrderId } = carQuote;

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CarQuoteBypassPayload = {
      ...values,
      carOrderId,
    };
    dispatch(carQuoteBypass(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          {initialValues && (
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({ isSubmitting }) => (
                <Form>
                  <Card>
                    <Card.Body>
                      <FormGroupControl
                        type="date"
                        name="activationDate"
                        label={t('car.activationDate')}
                        groupProps={GROUP_PROPS}
                        required
                      />

                      <FormGroupControl
                        name="frontlineNote"
                        label={t('common:frontlineNote')}
                        as="textarea"
                        rows={2}
                        groupProps={GROUP_PROPS}
                      />
                    </Card.Body>

                    <Card.Footer className="bg-white border-0 text-center my-4">
                      <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                        <span className="me-2">Bypass</span>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </Card.Footer>
                  </Card>
                </Form>
              )}
            </Formik>
          )}
        </Col>

        <Col lg={4}>
          <Card className="mb-4">
            <CarQuotePremiumCardBody {...carQuote} />
          </Card>
          <SupportCard />
        </Col>
      </Row>
    </Container>
  );
};

export { CarQuoteBuyBypassPage };
