import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

import { CarModel } from '@models/CarModel';
import { Expand, NoData, Loading, StepFormProps } from '@components/Base';
import { GetAllCarModelParams } from '@apis/CarModelApi';

import { selectCarQuotePage } from '../selector';
import { carModelsFetch, stateUpdate } from '../action';
import { StateUpdatePayload } from '../type';
import { FormValues } from './type';
import { Item } from './item';
import { CardHeader } from './card-header';

export const carModelValidationSchema = Yup.object({
  carModelId: Yup.number().required(),
});

const CarModelStepForm = ({ onNext, onPrev }: StepFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { carBrand, carModelGroups } = useSelector(selectCarQuotePage);
  const carModels = carModelGroups[values.carBrandId];
  const [expanded, setExpanded] = useState(false);
  const isLoading = isUndefined(carModels);
  const isError = isNull(carModels);
  const isReady = !isLoading && !isError;
  const hasCarModels = !isEmpty(carModels);

  const handleItemClick = useCallback((carModel: CarModel) => {
    const state: StateUpdatePayload = { carModel };

    setFieldValue('carModelId', carModel.id);
    dispatch(stateUpdate(state));
    onNext?.({ carModelId: carModel.id });
  }, []);

  const renderItem = useCallback((carModel: CarModel) => {
    const selected = values.carModelId === carModel.id;

    return (
      <Col xs={6} sm={6} md={6} lg={6} xl={4} key={`car-model-${carModel.id}`} className="mb-3">
        <Item
          testID={classNames('car-model-item', { selected })}
          selected={selected}
          onClick={() => handleItemClick(carModel)}
        >
          {carModel.name}
        </Item>
      </Col>
    );
  }, []);

  useEffect(() => {
    if (isLoading) {
      const payload: GetAllCarModelParams = { carBrandId: values.carBrandId };
      dispatch(carModelsFetch(payload));
    }

    if (values.carModelId) {
      setExpanded(true);
    }
  }, []);

  return (
    <Card>
      <CardHeader
        title={t('carInsuranceQuote.carModel.title', { carBrandName: carBrand?.name })}
        onClick={() => onPrev?.({ carModelId: undefined })}
      />
      <Card.Body>
        {isLoading && <Loading />}
        {isReady && !hasCarModels && <NoData />}
        {isReady && hasCarModels && (
          <Expand
            expand={expanded}
            wrapper={Row}
            wrapperProps={{ className: 'mb-n3' }}
            items={carModels}
            renderItem={renderItem}
            onExpand={() => setExpanded(true)}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export { CarModelStepForm };
