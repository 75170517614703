import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isLoggedIn } from 'axios-jwt';
import { Nav, Navbar, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import UserNavDropdown from './UserNavDropdown';
import ProductNavDropdown from './ProductNavDropdown';
import HeaderNavLink from './HeaderNavLink';
import HeaderNavbarBrand from '../HeaderNavbarBrand';
import LocaleButtonGroup from './LocaleButtonGroup';

const HeaderPrimary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSignIn = useCallback(() => {
    navigate('/sign-in');
  }, []);

  return (
    <Navbar bg="body" variant="light" expand="lg" className="shadow-sm">
      <Container>
        <HeaderNavbarBrand />
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Collapse id="navbar">
          <Nav className={classNames("me-auto", { "mb-2 mb-lg-0": !isLoggedIn()})}>
            <ProductNavDropdown />
            <HeaderNavLink />
          </Nav>

          {isLoggedIn() && (
            <div className="mb-2 mb-lg-0">
              <UserNavDropdown />
            </div>
          )}

          {!isLoggedIn() && (
            <div className="mb-3 mb-lg-0">
              <Button className="w-100" variant="light" onClick={handleSignIn}>
                <FontAwesomeIcon className="fa-fw me-2" icon={faUser} />
                {t('signIn', { ns: 'common' })}
              </Button>
            </div>
          )}

          <div className="mb-2 mb-lg-0 ms-lg-4">
            <LocaleButtonGroup />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export { HeaderPrimary };
