import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Container, Row, Button } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import CarQuotePremiumCardBody from '@components/Feature/CarQuote/CarQuotePremiumCardBody';
import { SupportCard } from '@components/Feature/Support/support-card';
import { FormGroupControl } from '@components/Base';
import { CarPlanTypes } from '@models/CarPlanType';

import { useCarQuoteEdit } from '../hook';
import { carQuoteCustom, CarQuoteCustomPayload } from '../action';
import { FormValues } from './schema';
import { useInitialValues, useValidationSchema } from './hook';
import { useTranslation } from 'react-i18next';

const GROUP_PROPS = { className: 'mb-3' };

const AmountControl = ({ name, title }: { name: string; title: string }) => {
  return (
    <fieldset>
      <legend>{title}</legend>

      <Row>
        <Col>
          <FormGroupControl name={`${name}Amount`} label="Premium" required groupProps={GROUP_PROPS} type="number" />
        </Col>
        <Col>
          <FormGroupControl
            name={`${name}DiscountAmount`}
            label="Discount"
            required
            groupProps={GROUP_PROPS}
            type="number"
          />
        </Col>
      </Row>
    </fieldset>
  );
};

const DiscountControl = () => {
  return (
    <fieldset>
      <legend>No Claim / Loyalty Discount</legend>

      <Row>
        <Col>
          <FormGroupControl
            required
            name="noClaimDiscountPercentage"
            label="No Claim Discount(%)"
            groupProps={GROUP_PROPS}
            type="number"
          />
        </Col>

        <Col>
          <FormGroupControl
            required
            name="loyaltyDiscountPercentage"
            label="Loyalty Discount(%)"
            groupProps={GROUP_PROPS}
            type="number"
          />
        </Col>
      </Row>
    </fieldset>
  );
};

const CarQuoteBuyCustomPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const carQuote = useCarQuoteEdit();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  if (!carQuote) return null;

  const { planType, accidentToDriver, passengerLiability, carOrderId } = carQuote;

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CarQuoteCustomPayload = {
      ...values,
      carOrderId,
    };
    dispatch(carQuoteCustom(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          {initialValues && (
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({ isSubmitting }) => (
                <Form>
                  <Card>
                    <Card.Body>
                      <AmountControl name="thirdParty" title="Third Party" />

                      {planType === CarPlanTypes.Comprehensive && <AmountControl name="ownDamage" title="Own Damage" />}

                      <AmountControl name="thirdPartyDeductible" title="Third Party Deductible" />

                      {planType === CarPlanTypes.Comprehensive && (
                        <>
                          <AmountControl name="ownDamageDeductible" title="Own Damage Deductible" />
                          <AmountControl name="theftDeductible" title="Theft Deductible" />
                        </>
                      )}

                      {accidentToDriver && <AmountControl name="accidentToDriver" title="Accident To Driver" />}

                      {passengerLiability && <AmountControl name="passengerLiability" title="Passenger Liability" />}

                      <AmountControl name="adminFee" title="Admin Fee" />

                      <DiscountControl />
                    </Card.Body>

                    <Card.Footer className="bg-white border-0 text-center my-4">
                      <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                        <span className="me-2">{t('common:update')}</span>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </Card.Footer>
                  </Card>
                </Form>
              )}
            </Formik>
          )}
        </Col>

        <Col lg={4}>
          <Card className="mb-4">
            <CarQuotePremiumCardBody {...carQuote} />
          </Card>
          <SupportCard />
        </Col>
      </Row>
    </Container>
  );
};

export { CarQuoteBuyCustomPage };
