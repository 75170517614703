import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormGroupControl } from '@components/Base';

import { carQuoteSendEmail, CarQuoteSendEmailPayload } from '../action';
import { useCarQuoteDetailsPageParams } from '../hook';
import { validationSchema } from './schema';
import { FormValues, useInitialValues } from './hook';
import { getCarQuoteDetailsPageRoute } from '../route';

const GROUP_PROPS = { className: 'mb-3' };

const CarQuoteSendEmailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useCarQuoteDetailsPageParams();
  const initialValues = useInitialValues();

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CarQuoteSendEmailPayload = { ...params, ...values };
    dispatch(carQuoteSendEmail(payload));
    setSubmitting(false);
    navigate(getCarQuoteDetailsPageRoute(params));
  };

  return (
    <Card>
      <Card.Body>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormGroupControl name="firstName" label={t('user.firstName')} required groupProps={GROUP_PROPS} />
            <FormGroupControl name="lastName" label={t('user.lastName')} required groupProps={GROUP_PROPS} />
            <FormGroupControl name="email" label={t('user.email')} required groupProps={GROUP_PROPS} />

            <Button type="submit" variant="primary" size="lg">
              {t('common:sendNow')}
            </Button>
          </Form>
        </Formik>
      </Card.Body>
    </Card>
  );
};

export { CarQuoteSendEmailPage };
