import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParseParams } from '@hooks/search-params';

import { carFetch, CarFetchPayload } from './action';
import { selectCarQuoteResultPage } from './selector';
import {
  CarParams,
  carParamsSchema,
  CarQuoteResultPageParams,
  carQuoteResultPageParamsSchema,
  CarQuotesFilterParams,
  carQuotesFilterParamsSchema,
} from './route';

export const useCarFetch = () => {
  const dispatch = useDispatch();
  const payload: CarFetchPayload = useParseParams<CarParams>(carParamsSchema);
  const { car } = useSelector(selectCarQuoteResultPage);

  useEffect(() => {
    dispatch(carFetch(payload));
  }, []);

  return car;
};

export const useCarQuotesFilterParams = () => useParseParams<CarQuotesFilterParams>(carQuotesFilterParamsSchema);

export const useCarQuotesResultPageParams = () =>
  useParseParams<Required<CarQuoteResultPageParams>>(carQuoteResultPageParamsSchema);
