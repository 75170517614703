import React, { useEffect } from 'react';
import { Container, Row, Col, Image, Breadcrumb } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHref } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNull, isUndefined } from 'lodash';

import MetaTag from '@components/MetaTag';
import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';
import { Loading } from '@components/Base';
import { formatDateTime } from '@utils/datetime';

import { selectArticleDetailsPage } from './selector';
import { articleFetch } from './action';

const ArticleDetailsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const homeHref = useHref('/');
  const articlesHref = useHref('/articles');
  const { articleId } = useParams();
  const { article } = useSelector(selectArticleDetailsPage);
  const isLoading = isUndefined(article);
  const isError = isNull(article);

  useEffect(() => {
    dispatch(articleFetch({ id: articleId }));
  }, []);

  return (
    <>
      {article && (
        <MetaTag
          pageName="articleDetailsPage"
          title={article.title}
          description={article.shortDescription}
          image={article.coverPhoto}
        />
      )}
      <HeaderPrimary />
      <Container className="mt-5">
        {isLoading && <Loading />}
        {isError && <p>We cannot find the article.</p>}
        {article && (
          <>
            <Breadcrumb>
              <Breadcrumb.Item href={homeHref}>{t('common:home')}</Breadcrumb.Item>
              <Breadcrumb.Item href={articlesHref}>{t('common:articles')}</Breadcrumb.Item>
              <Breadcrumb.Item active>{article.title}</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
              <Col lg={8}>
                <article className="my-5">
                  <h1>{article.title}</h1>
                  <div>{formatDateTime(article.publishedAt)}</div>
                  <div className="my-5 text-center">
                    <Image src={article.coverPhoto} fluid />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: article.content }} />
                </article>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};

export { ArticleDetailsPage };
