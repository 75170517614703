import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Alert } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import FormikDropzone from '../FormikDropzone';
import { photoManagerDialogPhotosUpload } from '../action';
import { PhotoManagerDialogUploaderFormValues, PhotoManagerDialogUploaderProps } from '../type';
import { selectPhotoManagerDialog } from '../selector';

type FormValues = PhotoManagerDialogUploaderFormValues & {
  photos: File[];
};

const Uploader = ({ recordId, recordType, name, limit }: PhotoManagerDialogUploaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { photos } = useSelector(selectPhotoManagerDialog);
  const remainingFiles = limit - (photos?.length ?? 0);
  const reachLimit = remainingFiles === 0;

  const initialValues: FormValues = useMemo(
    () => ({
      recordId,
      recordType,
      name,
      photos: [],
    }),
    [],
  );

  const handleSubmit = useCallback((values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    dispatch(photoManagerDialogPhotosUpload({ ...values }));

    setSubmitting(false);
    resetForm();
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, isSubmitting, handleSubmit: onSubmit }) => {
        const shouldRenderSubmit = values.photos.length > 0;

        return (
          <form onSubmit={onSubmit}>
            {reachLimit && (
              <div className="d-flex justify-content-center text-center">
                <Alert variant="info">{t('photoManagerDialog.tabs.upload.reachLimitMsg')}</Alert>
              </div>
            )}
            {!reachLimit && <FormikDropzone name="photos" maxFiles={remainingFiles} />}
            {shouldRenderSubmit && (
              <Button type="submit" className="my-3" disabled={isSubmitting}>
                {t('submit', { ns: 'common' })}
              </Button>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export { Uploader };
