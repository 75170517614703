import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { lowerCase } from 'lodash';
import classNames from 'classnames';

import { CarQuote } from '@models/CarQuote';
import { amountToKhr, amountToUsd } from '@utils/currency';
import { CarPlanTypes } from '@models/CarPlanType';
import { AmountWithDiscount } from '@components/Base';

interface Props {
  carQuote: CarQuote;
}

const PremiumBreakdownCard = ({ carQuote }: Props) => {
  const { t } = useTranslation();
  const {
    planType,
    totalPremium,
    totalPremiumKhr,
    thirdPartyAmount,
    thirdPartyDiscountAmount,
    thirdPartyFinalAmount,
    ownDamageAmount,
    ownDamageDiscountAmount,
    ownDamageFinalAmount,
    accidentToDriverAmount,
    accidentToDriverDiscountAmount,
    accidentToDriverFinalAmount,
    passengerLiabilityAmount,
    passengerLiabilityDiscountAmount,
    passengerLiabilityFinalAmount,
    adminFeeAmount,
    adminFeeDiscountAmount,
    adminFeeFinalAmount,
  } = carQuote;
  const hasAddonCovers = !!Number(accidentToDriverAmount) || !!Number(passengerLiabilityAmount);

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>{t('carInsuranceQuote.basicCovers')}</Card.Title>
        <ListGroup as="ol" numbered variant="flush">
          {planType === CarPlanTypes.Comprehensive && (
            <ListGroup.Item className={classNames('d-flex justify-content-between align-items-start border-0')}>
              <span className="me-auto">{t('common:ownDamageTheftCoverPremium')}</span>
              <AmountWithDiscount
                amount={ownDamageAmount}
                discountAmount={ownDamageDiscountAmount}
                finalAmount={ownDamageFinalAmount}
              />
            </ListGroup.Item>
          )}

          <ListGroup.Item className={classNames('d-flex justify-content-between align-items-start border-0')}>
            <span className="me-auto">{t('common:thirdPartyCoverPremium')}</span>
            <AmountWithDiscount
              amount={thirdPartyAmount}
              discountAmount={thirdPartyDiscountAmount}
              finalAmount={thirdPartyFinalAmount}
            />
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>

      {hasAddonCovers && (
        <Card.Body>
          <Card.Title>{t('carInsuranceQuote.addons')}</Card.Title>
          <ListGroup as="ol" numbered variant="flush">
            {!!Number(accidentToDriverAmount) && (
              <ListGroup.Item className={classNames('d-flex justify-content-between align-items-start border-0')}>
                <span className="me-auto">{t('carInsuranceQuote.accidentToDriver.name')}</span>
                <AmountWithDiscount
                  amount={accidentToDriverAmount}
                  discountAmount={accidentToDriverDiscountAmount}
                  finalAmount={accidentToDriverFinalAmount}
                />
              </ListGroup.Item>
            )}

            {!!Number(passengerLiabilityAmount) && (
              <ListGroup.Item className={classNames('d-flex justify-content-between align-items-start border-0')}>
                <span className="me-auto">{t('carInsuranceQuote.passengerLiability.name')}</span>
                <AmountWithDiscount
                  amount={passengerLiabilityAmount}
                  discountAmount={passengerLiabilityDiscountAmount}
                  finalAmount={passengerLiabilityFinalAmount}
                />
              </ListGroup.Item>
            )}
          </ListGroup>
        </Card.Body>
      )}

      <Card.Body>
        <Card.Title className="d-flex justify-content-between border-top pe-3 pt-3">
          <span className="me-auto">{t('common:adminFeeAmount')}</span>
          <AmountWithDiscount
            amount={adminFeeAmount}
            discountAmount={adminFeeDiscountAmount}
            finalAmount={adminFeeFinalAmount}
          />
        </Card.Title>

        <Card.Title className="d-flex justify-content-between border-top pe-3 pt-3">
          <span className="me-auto">{t('common:totalPremium')}</span>
          <span>
            {amountToUsd(totalPremium)}/{lowerCase(t('common:year'))}
          </span>
        </Card.Title>

        <div className="d-flex justify-content-between border-top pe-3 pt-3">
          <span className="me-auto">&nbsp;</span>
          <h5>
            {amountToKhr(totalPremiumKhr)}/{lowerCase(t('common:year'))}
          </h5>
        </div>
      </Card.Body>
    </Card>
  );
};

export { PremiumBreakdownCard };
