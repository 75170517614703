import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { BackButton, RowItem } from '@components/Base';
import { CarPlanTypes } from '@models/CarPlanType';
import { amountToUsd, amountToKhr } from '@utils/currency';
import { useParamsCarOrderId } from '@hooks/params';

import { carOrderRenew, CarOrderRenewPayload } from './action';
import { useCarOrderPreview } from './hook';

const CarPolicyRenewal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carOrderId = useParamsCarOrderId();
  const carOrder = useCarOrderPreview();

  const handleBack = () => {
    navigate('/user-dashboard/policy', { replace: true });
  };

  const handleRenew = () => {
    const payload: CarOrderRenewPayload = { id: carOrderId };
    dispatch(carOrderRenew(payload));
  };

  if (!carOrder) return null;

  const {
    totalPremium,
    totalPremiumKhr,
    ownDamageFinalAmount,
    thirdPartyFinalAmount,
    accidentToDriverFinalAmount,
    passengerLiabilityFinalAmount,
    planType,
    accidentToDriver,
    passengerLiability,
    insurer: { carProductEnabled },
  } = carOrder;

  return (
    <section data-testid="car-policy-renewal-section">
      <BackButton onClick={handleBack} className="mb-4" />
      <h5 className="mb-4">{t('common:renewPolicy')}</h5>
      {carOrder && (
        <>
          {!carProductEnabled && (
            <Alert variant="warning">
              <Alert.Heading>{t('carInsuranceQuote.disabledTitle')}</Alert.Heading>
              <p>{t('carInsuranceQuote.disabledDesc')}</p>
            </Alert>
          )}

          <Card>
            <Card.Body>
              <RowItem label={t('common:totalPremium')} value={amountToUsd(totalPremium)} className="mb-2" />
              <RowItem label={t('common:totalPremiumKhr')} value={amountToKhr(totalPremiumKhr)} className="mb-2" />
              {planType === CarPlanTypes.Comprehensive && (
                <RowItem
                  label={t('common:ownDamageTheftPremium')}
                  value={amountToUsd(ownDamageFinalAmount)}
                  className="mb-2"
                />
              )}
              <RowItem
                label={t('common:thirdPartyPremium')}
                value={amountToUsd(thirdPartyFinalAmount)}
                className="mb-2"
              />
              {accidentToDriver && (
                <RowItem
                  label={t('common:accidentToDriverPremium')}
                  value={amountToUsd(accidentToDriverFinalAmount)}
                  className="mb-2"
                />
              )}
              {passengerLiability && (
                <RowItem
                  label={t('common:passengerLiabilityPremium')}
                  value={amountToUsd(passengerLiabilityFinalAmount)}
                  className="mb-2"
                />
              )}
            </Card.Body>

            <Card.Body>
              <Button disabled={!carProductEnabled} onClick={handleRenew}>
                {t('common:proceedToPayment')}
              </Button>
            </Card.Body>
          </Card>
        </>
      )}
    </section>
  );
};

export { CarPolicyRenewal };
