import React from 'react';

import { amountToUsd } from '@utils/currency';
import { NumericString } from '@utils/typescript';

type Props = {
  amount: NumericString;
  discountAmount: NumericString;
  finalAmount: NumericString;
};

const AmountWithDiscount = ({ amount, discountAmount, finalAmount }: Props) => {
  if (!!Number(discountAmount)) {
    return (
      <span>
        <del>{amountToUsd(amount)}</del>
        &nbsp;
        {amountToUsd(finalAmount)}
      </span>
    );
  }

  return <span>{amountToUsd(finalAmount)}</span>;
};

export { AmountWithDiscount };
