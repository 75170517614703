import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { isLoggedIn } from 'axios-jwt';
import { useTranslation } from 'react-i18next';
import store from '@store';
import { history } from '@utils/history';

import './i18n';
import { currentUserFetch } from './Auth';
import MetaTag from './MetaTag';
import Home from './Home';

import ThankYou from './ThankYou';
import UserRegistration from './UserRegistration';
import UserDashboard from './UserDashboard';
import CamDx from './CamDx';
import ProtectedRoute from './ProtectedRoute';
import AboutUsPage from './AboutUsPage';
import ContactUsPage from './ContactUsPage';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import CancellationAndRefund from './CancellationAndRefund';
import ArticlePage from './ArticlePage';
import ArticleDetailsPage from './ArticleDetailsPage';
import NotFoundPage from './NotFoundPage';
import ToastProvider from './ToastProvider';

import CarInsurancePage from './CarInsurancePage';
import CarQuotePage from './CarQuotePage';
import CarQuoteResultPage from './CarQuoteResultPage';
import CarQuoteComparePage from './CarQuoteComparePage';
import CarQuoteDetailsRoute from './CarQuoteDetailsRoute';
import CarQuoteBuyRoute from './CarQuoteBuyRoute';
import CarOrderPaymentPage from './CarOrderPaymentPage';

import HealthInsurancePage from './HealthInsurancePage';
import HealthQuoteResultPage from './HealthQuoteResultPage';
import HealthQuoteComparePage from './HealthQuoteComparePage';
import HealthQuoteDetailsRoute from './HealthQuoteDetailsRoute';
import HealthQuoteBuyRoute from './HealthQuoteBuyRoute';
import HealthOrderPaymentPage from './HealthOrderPaymentPage';

import PersonalAccidentInsurancePage from './PersonalAccidentInsurancePage';
import PersonalAccidentQuoteResultPage from './PersonalAccidentQuoteResultPage';
import PersonalAccidentQuoteComparePage from './PersonalAccidentQuoteComparePage';
import PersonalAccidentQuoteDetailsRoute from './PersonalAccidentQuoteDetailsRoute';
import PersonalAccidentQuoteBuyRoute from './PersonalAccidentQuoteBuyRoute';
import PersonalAccidentOrderPaymentPage from './PersonalAccidentOrderPaymentPage';

import CancerInsurancePage from './CancerInsurancePage';
import CancerQuoteResultPage from './CancerQuoteResultPage';
import CancerQuoteDetailsRoute from './CancerQuoteDetailsRoute';
import CancerQuoteBuyRoute from './CancerQuoteBuyRoute';
import CancerQuoteComparePage from './CancerQuoteComparePage';
import CancerOrderPaymentPage from './CancerOrderPaymentPage';

import { CAR_QUOTE_RESULT_PAGE_URL } from './CarQuoteResultPage/route';
import { CAR_QUOTE_COMPARE_PAGE_URL } from './CarQuoteComparePage/route';
import { CAR_QUOTE_DETAILS_ROUTE_URL } from './CarQuoteDetailsRoute/route';
import { CAR_QUOTE_BUY_ROUTE_URL } from './CarQuoteBuyRoute/route';
import { CAR_ORDER_PAYMENT_PAGE_URL } from './CarOrderPaymentPage/route';
import { HEALTH_QUOTE_RESULT_PAGE_URL } from './HealthQuoteResultPage/route';
import { HEALTH_QUOTE_COMPARE_PAGE_URL } from './HealthQuoteComparePage/route';
import { HEALTH_QUOTE_DETAILS_ROUTE_URL } from './HealthQuoteDetailsRoute/route';
import { HEALTH_QUOTE_BUY_ROUTE_URL } from './HealthQuoteBuyRoute/route';
import { HEALTH_ORDER_PAYMENT_PAGE_URL } from './HealthOrderPaymentPage/route';
import { PERSONAL_ACCIDENT_QUOTE_RESULT_PAGE_URL } from './PersonalAccidentQuoteResultPage/route';
import { PERSONAL_ACCIDENT_QUOTE_COMPARE_PAGE_URL } from './PersonalAccidentQuoteComparePage/route';
import { PERSONAL_ACCIDENT_QUOTE_DETAILS_ROUTE_URL } from './PersonalAccidentQuoteDetailsRoute/route';
import { PERSONAL_ACCIDENT_QUOTE_BUY_ROUTE_URL } from './PersonalAccidentQuoteBuyRoute/route';
import { PERSONAL_ACCIDENT_ORDER_PAYMENT_PAGE_URL } from './PersonalAccidentOrderPaymentPage/route';
import { CANCER_QUOTE_RESULT_PAGE_URL } from './CancerQuoteResultPage/route';
import { CANCER_QUOTE_DETAILS_ROUTE_URL } from './CancerQuoteDetailsRoute/route';
import { CANCER_QUOTE_BUY_ROUTE_URL } from './CancerQuoteBuyRoute/route';
import { CANCER_QUOTE_COMPARE_PAGE_URL } from './CancerQuoteComparePage/route';
import { CANCER_ORDER_PAYMENT_PAGE_URL } from './CancerOrderPaymentPage/route';

const App = () => {
  const dispatch = useDispatch();
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(currentUserFetch());
    }
  }, []);

  return (
    <Routes>
      <Route index element={<Home />} />

      <Route path="/car-insurance-quote/*" element={<CarQuotePage />} />
      <Route path={CAR_QUOTE_RESULT_PAGE_URL} element={<CarQuoteResultPage />} />
      <Route path={CAR_QUOTE_COMPARE_PAGE_URL} element={<CarQuoteComparePage />} />
      <Route path={CAR_QUOTE_DETAILS_ROUTE_URL} element={<CarQuoteDetailsRoute />} />
      <Route path={CAR_QUOTE_BUY_ROUTE_URL} element={<ProtectedRoute element={<CarQuoteBuyRoute />} />} />
      <Route path={CAR_ORDER_PAYMENT_PAGE_URL} element={<ProtectedRoute element={<CarOrderPaymentPage />} />} />

      <Route path="/health-insurance/*" element={<HealthInsurancePage />} />
      <Route path={HEALTH_QUOTE_RESULT_PAGE_URL} element={<HealthQuoteResultPage />} />
      <Route path={HEALTH_QUOTE_COMPARE_PAGE_URL} element={<HealthQuoteComparePage />} />
      <Route path={HEALTH_QUOTE_DETAILS_ROUTE_URL} element={<HealthQuoteDetailsRoute />} />
      <Route path={HEALTH_QUOTE_BUY_ROUTE_URL} element={<ProtectedRoute element={<HealthQuoteBuyRoute />} />} />
      <Route path={HEALTH_ORDER_PAYMENT_PAGE_URL} element={<ProtectedRoute element={<HealthOrderPaymentPage />} />} />

      <Route path="/personal-accident-insurance/*" element={<PersonalAccidentInsurancePage />} />
      <Route path={PERSONAL_ACCIDENT_QUOTE_RESULT_PAGE_URL} element={<PersonalAccidentQuoteResultPage />} />
      <Route path={PERSONAL_ACCIDENT_QUOTE_COMPARE_PAGE_URL} element={<PersonalAccidentQuoteComparePage />} />
      <Route path={PERSONAL_ACCIDENT_QUOTE_DETAILS_ROUTE_URL} element={<PersonalAccidentQuoteDetailsRoute />} />
      <Route
        path={PERSONAL_ACCIDENT_QUOTE_BUY_ROUTE_URL}
        element={<ProtectedRoute element={<PersonalAccidentQuoteBuyRoute />} />}
      />
      <Route
        path={PERSONAL_ACCIDENT_ORDER_PAYMENT_PAGE_URL}
        element={<ProtectedRoute element={<PersonalAccidentOrderPaymentPage />} />}
      />

      <Route path="/cancer-insurance/*" element={<CancerInsurancePage />} />
      <Route path={CANCER_QUOTE_RESULT_PAGE_URL} element={<CancerQuoteResultPage />} />
      <Route path={CANCER_QUOTE_DETAILS_ROUTE_URL} element={<CancerQuoteDetailsRoute />} />
      <Route path={CANCER_QUOTE_BUY_ROUTE_URL} element={<ProtectedRoute element={<CancerQuoteBuyRoute />} />} />
      <Route path={CANCER_QUOTE_COMPARE_PAGE_URL} element={<CancerQuoteComparePage />} />
      <Route path={CANCER_ORDER_PAYMENT_PAGE_URL} element={<ProtectedRoute element={<CancerOrderPaymentPage />} />} />

      <Route path="/articles" element={<ArticlePage />} />
      <Route path="/articles/:articleId" element={<ArticleDetailsPage />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/sign-in/*" element={<UserRegistration />} />
      <Route path="/user-dashboard/*" element={<ProtectedRoute element={<UserDashboard />} />} />
      {/* callback when cam_dx login success */}
      <Route path="/camdx/auth/success" element={<CamDx />} />
      {/* static pages */}
      <Route path="/car-insurance" element={<CarInsurancePage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/contact-us" element={<ContactUsPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/cancellation-and-refund" element={<CancellationAndRefund />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const Container = () => {
  const { i18n } = useTranslation();

  return (
    <Provider store={store}>
      <BrowserRouter basename={`/${i18n.language}`}>
        <MetaTag pageName="home" />
        <App />
        <ToastProvider />
      </BrowserRouter>
    </Provider>
  );
};

export default Container;
