import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { array, number, object, string } from 'yup';
import 'yup-phone-lite';

import { StepType } from '@components/Base';

import { selectPersonalAccidentQuoteBuyRoute } from '../selector';

export const useSteps = () => {
  const { t } = useTranslation();
  const { personalAccidentQuote } = useSelector(selectPersonalAccidentQuoteBuyRoute);

  return useMemo(() => {
    if (!personalAccidentQuote) return;

    const { personalAccidentPlanQuestions } = personalAccidentQuote;

    const contactMe = object({
      firstName: string().required(t('validation:required')),
      lastName: string().required(t('validation:required')),
      occupation: string().required(t('validation:required')),
      dob: string().required(t('validation:required')),
      age: number().required(t('validation:required')).positive().integer(),
      gender: string().required(t('validation:required')),
      email: string().email(),
      mobileNumber: string().required(t('validation:required')).phone('KH', t('validation:validPhone', { countryName: t('common:cambodia') })),
      address: string().required(t('validation:required')),
    });

    const contactMember = contactMe.omit(['gender', 'email', 'mobileNumber', 'address']);

    const questionMember = object({
      questionnaire: array()
        .of(
          object({
            answer: string(),
          })
        )
        .compact((value: { answer: string }) => value?.answer !== 'no')
        .length(personalAccidentPlanQuestions.length),
    });

    const steps: StepType[] = [
      {
        pathname: 'contact',
        validationSchema: object({
          me: contactMe,
          spouse: contactMember.default(undefined),
          sons: array().of(contactMember),
          daughters: array().of(contactMember),
        }),
      },
      {
        pathname: 'question',
        validationSchema: object({
          me: questionMember,
          spouse: questionMember.default(undefined),
          sons: array().of(questionMember),
          daughters: array().of(questionMember),
        }),
      },
    ];

    return steps;
  }, [personalAccidentQuote]);
}
