import React from 'react';
import { useField } from 'formik';
import { ToggleButton } from 'react-bootstrap';
import { FormValuesContact } from './type';

const FormikToggleButton = ({ name, label }: { name: string; label: string }) => {
  const [field, , helpers] = useField(name);
  const value = field.value as FormValuesContact;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue({ ...value, checked: event.currentTarget.checked });
  };

  return (
    <ToggleButton
      {...field}
      id={name}
      checked={value.checked}
      onChange={handleChange}
      type="checkbox"
      variant="outline-primary"
      className="btn-lg w-100"
    >
      {label}
    </ToggleButton>
  );
};

export { FormikToggleButton };
