import { object, number, string, boolean } from 'yup';

import { CarPlanTypes } from '@models/CarPlanType';
import { stringify } from 'qs';

export type CarParams = {
  carId: number;
  manufacturedYear: number;
};

export type CarQuotesFilterParams = {
  idvAmount: number;
  planType: CarPlanTypes;
  accidentToDriver: boolean;
  passengerLiability: boolean;
  promotionCode: string;
};

export type CarQuoteResultPageParams = CarParams & Partial<CarQuotesFilterParams>;

export const carParamsSchema = object({
  carId: number(),
  manufacturedYear: number(),
});

export const carQuotesFilterParamsSchema = object({
  idvAmount: number().default(0),
  planType: string().default(CarPlanTypes.ThirdParty),
  accidentToDriver: boolean().default(false),
  passengerLiability: boolean().default(false),
  promotionCode: string().default(''),
});

export const carQuoteResultPageParamsSchema = carParamsSchema.concat(carQuotesFilterParamsSchema);

export const CAR_QUOTE_RESULT_PAGE_URL = '/car-insurance-quote-results';

export const getCarQuoteResultPageRoute = (params: CarQuoteResultPageParams) => {
  return {
    pathname: CAR_QUOTE_RESULT_PAGE_URL,
    search: stringify(params),
  };
}
