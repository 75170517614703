import { tEnum } from '@components/i18n';
import { KeysToCamelCase } from '@utils/typescript';

export enum Genders {
  Male = 'male',
  Female = 'female',
}

export const tGender = (gender: Genders) => tEnum('Genders', gender);

export type UserResponse = {
  id: number;
  gender: Genders;
  dob: string;
  occupation: string;
  personal_code: string;
  email: string;
  mobile_number: string;
  address: string;
  first_name: string;
  first_name_km: string;
  last_name: string;
  last_name_km: string;
  full_name: string;
  full_name_km: string;
};

export type User = KeysToCamelCase<UserResponse>;
