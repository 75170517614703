import { KeysToCamelCase } from '@utils/typescript';
import { Genders } from './User';

export enum UserRoles {
  Customer = 'customer',
  Frontline = 'frontline',
}

export enum UserStatuses {
  Pending = 'pending',
  Confirmed = 'confirmed',
}

export type UserProfileResponse = {
  id: number;
  role: UserRoles;
  status: UserStatuses;
  gender: Genders;
  access_token: string;
  occupation: string;
  dob: string;
  personal_code: string;
  email: string;
  mobile_number: string;
  address: string;
  first_name: string;
  first_name_km: string;
  last_name: string;
  last_name_km: string;
  full_name: string;
  full_name_km: string;
  car_promotion_codes: string[];
};

export type UserProfile = KeysToCamelCase<UserProfileResponse>;
