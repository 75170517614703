import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { CarPlanTypes } from '@models/CarPlanType';

import { selectCarQuoteResultPage } from '../../selector';
import { InsuredDeclaredValueModal } from './insured-declared-value-modal';
import { CarUnknownModal } from './car-unknown-modal';
import { useFilterContext } from '../../FilterProvider';

const InsuredDeclaredValue = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { car } = useSelector(selectCarQuoteResultPage);
  const {
    filters: { planType },
    updateFilters,
  } = useFilterContext();
  const isCarQuoteThirdPartyPlan = planType === CarPlanTypes.ThirdParty;

  const handleConfirm = (value: number) => {
    setShow(false);
    updateFilters({ idvAmount: value });
  };

  return (
    <>
      <div className="py-2">
        <div className="py-1">
          <span className="me-2 pe-1 fw-bold">{t('carInsuranceQuote.insuredDeclaredValue.title')}</span>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover>
                <Popover.Body>{t('carInsuranceQuote.insuredDeclaredValue.description')}</Popover.Body>
              </Popover>
            }
          >
            <FontAwesomeIcon icon={faCircleInfo} size="sm" />
          </OverlayTrigger>
        </div>
        <Button onClick={() => setShow(true)} disabled={isCarQuoteThirdPartyPlan} variant="light">
          <span>
            {isCarQuoteThirdPartyPlan
              ? t('carInsuranceQuote.insuredDeclaredValue.selectIdvNotApplicable')
              : t('carInsuranceQuote.insuredDeclaredValue.selectIdv')}
          </span>
          &nbsp;
          <FontAwesomeIcon icon={faAngleDown} size="sm" />
        </Button>
      </div>

      {car?.unknown && !isCarQuoteThirdPartyPlan && <CarUnknownModal />}

      {car && (
        <InsuredDeclaredValueModal
          show={show}
          lowestAmount={car.lowestAmount}
          highestAmount={car.highestAmount}
          onHide={() => setShow(false)}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};

export { InsuredDeclaredValue };
